import React, { useState } from 'react';
import { Modal, Button, Form, ListGroup, Row, Col, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

const ImportSheetModal = ({ show, handleClose, openMenuFrameId,userId }) => {
  const [file, setFile] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [sheetName, setSheetName] = useState('');
  const [privacyType, setPrivacyType] = useState('public'); // Default to 'public'
  const [loading, setLoading] = useState(false); 

  const columnTypes = [
    // { label: 'Fixed', value: 'fixed' },
    { label: 'Text', value: 'text' },
    { label: 'status', value: 'dropdown' },
    // { label: 'priority', value: 'dropdown' },
    { label: 'timeline', value: 'timeline' },
    { label: 'People', value: 'users' },
    { label: 'Files', value: 'file' },
    { label: 'Number', value: 'integer' },
    { label: 'MultiDropdown', value: 'multiDropdown' },
    { label: 'Date', value: 'date' },
  ];

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);

      // Send the file to get column headers
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/find-the-headers/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setHeaders(response.data.headers);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleColumnSelect = (index, columnType) => {
    setSelectedColumns((prev) => {
      const updatedColumns = { ...prev };
      if (updatedColumns[index]) {
        delete updatedColumns[index];
      } else {
        updatedColumns[index] = {
          name: headers[index].header, // Set the header name
          type: columnType || 'text',  // Default to 'text' if no type is selected
        };
      }
      return updatedColumns;
    });
  };

  const handleColumnTypeChange = (index, newType) => {
    setSelectedColumns((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        type: newType,
      },
    }));
  };

  const handleImport = async () => {
    setLoading(true); // Start loading spinner
  
    try {
      // First, create the sheet
      const payload = {
        workspace_id: openMenuFrameId, // Replace with the actual workspace ID
        name: sheetName,
        privacy_type: privacyType,
        user: userId
      };
      const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/sheets/`, payload);
      const sheetId = response.data.id;
  
      // Then, proceed with importing the data using the created sheetId
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sheet_id', sheetId);
  
      // Construct the cols array, ensuring that the 0th index type is always 'fixed'
      const cols = Object.keys(selectedColumns).map((index) => ({
        index: parseInt(index, 10),
        name: selectedColumns[index].name, // Include name in the payload
        type: index === '0' ? 'fixed' : selectedColumns[index].type, // Ensure 0th column type is 'fixed'
      }));
  
      formData.append('cols', JSON.stringify(cols));
  
      await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/import-data/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Handle success (e.g., show a success message or update UI)
      handleClose();
      toast.success('Data Imported Successfully!');
    } catch (error) {
      console.error('Error importing data:', error);
      toast.error('Error importing data.');
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };
  

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Import Sheet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="sheetName" className="mb-3">
            <Form.Label>Sheet Name</Form.Label>
            <Form.Control
              type="text"
              value={sheetName}
              onChange={(e) => setSheetName(e.target.value)}
              placeholder="Enter the name of the sheet"
            />
          </Form.Group>

          <Form.Group controlId="privacyType" className="mb-3">
            <Form.Label>Privacy Type</Form.Label>
            <div className="d-flex">
              <Form.Check
                type="radio"
                label="Public"
                name="privacyType"
                value="public"
                checked={privacyType === 'public'}
                onChange={() => setPrivacyType('public')}
                className="me-3"
              />
              <Form.Check
                type="radio"
                label="Private"
                name="privacyType"
                value="private"
                checked={privacyType === 'private'}
                onChange={() => setPrivacyType('private')}
                className="me-3"
              />
              <Form.Check
                type="radio"
                label="Sharable"
                name="privacyType"
                value="sharable"
                checked={privacyType === 'sharable'}
                onChange={() => setPrivacyType('sharable')}
              />
            </div>
          </Form.Group>

          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload File (CSV/XLSX)</Form.Label>
            <Form.Control type="file" accept=".csv, .xlsx" onChange={handleFileChange} />
          </Form.Group>

          {headers.length > 0 && (
            <ListGroup className="mt-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {headers.map((header, index) => (
                <ListGroup.Item key={index}>
                  <Row>
                    <Col xs={6}>
                      <Form.Check
                        type="checkbox"
                        label={header.header}
                        checked={selectedColumns.hasOwnProperty(index)}
                        onChange={() => handleColumnSelect(index, selectedColumns[index]?.type)}
                      />
                    </Col>
                    <Col xs={6}>
                    <Form.Control
            as="select"
            value={index === 0 ? 'fixed' : selectedColumns[index]?.type || 'text'} // Set the first column's type to 'fixed'
            onChange={(e) => handleColumnTypeChange(index, e.target.value)}
            disabled={!selectedColumns.hasOwnProperty(index) || index === 0} // Disable the select for the first column
          >
            {columnTypes.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </Form.Control>
                      
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleImport}
          disabled={!file || !sheetName || loading}
        >
          {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Import'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportSheetModal;
