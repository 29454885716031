import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { AiOutlineShareAlt, AiOutlineLock, AiOutlineEdit, AiOutlineDelete, AiOutlineGlobal, AiOutlineFilter } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/styles/Mainframe.css'; // Adjust path as necessary
import { addFrame, deleteFrame, updateFrame } from '../../features/workspaces/workspaceActions';
import ConfirmationPopup from '../common/ConfirmationPopup';
import Select from 'react-select';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
// import MultiSelect from "editable-creatable-multiselect";
import axios from 'axios';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const MainFramesList = ({ user,setsidebartrue,sidebartrue }) => {
  // const [suggestions, setSuggestions] = useState([
  //   { name: "Microsoft", id: 1 },
  //   { name: "Apple", id: 2 },
  //   { name: "Google", id: 3 },
  //   { name: "JetBrains", id: 4 },
  //   { name: "Airbnb", id: 5 },
  //   { name: "Amazon", id: 6 },
  //   { name: "Tesla", id: 7 },
  //   { name: "NVIDIA", id: 8 },
  //   { name: "Samsung", id: 9 },
  //   { name: "Netflix", id: 10 },
  //   { name: "Palantir", id: 11 },
  // ]);
  // const [selectedList, setSelectedList] = useState([]);
  const [options, setOptions] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [frameName, setFrameName] = useState('');
  const [privacyType, setPrivacyType] = useState('');
  const [description, setDescription] = useState('');
  const [iconFile, setIconFile] = useState(null);
  const dispatch = useDispatch();
  const framess = useSelector((state) => state.frames.frames);
  const status = useSelector((state) => state.frames.status);
  const selectedDepartment = useSelector((state) => state?.department?.value)
  const selectedDivision = useSelector((state) => state)
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [frames, setFrames] = useState([])
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deletingFrameId, setDeletingFrameId] = useState(null);
  const [showTagMenu, setShowTagMenu] = useState(false);
  const [existingIconFile,setexistingIconFile]=useState()
  const menuRef = useRef(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [frameId, setFrameId] = useState(null);
  console.log("selectedDepartment", selectedDepartment, selectedDivision)
  const dept = localStorage.getItem("HDept")
  const Div = localStorage.getItem("HDiv")
  const DeptId = localStorage.getItem('HDept');
  const DivId = localStorage.getItem('HDiv');
  /*eslint-disable*/

  useEffect(() => {
    if (status === 'idle') {
      fetchFramesData()
      fetchTags();
    }
  }, [dispatch, status, DeptId, DivId]);

  useEffect(()=>{
    fetchFramesData();
  },[sidebartrue]);
  /*eslint-enable*/

  const fetchFramesData = async () => {
    if(user?.userid){
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/filter-workspaces/${DeptId}/${user?.userid}/`);
        if (response?.data) {
          const combinedFrames = [
            ...response.data.my_workspaces,
            ...response.data.public,
            ...response.data.shared
          ];
  
          setFrames(combinedFrames);
        }
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    }
   
    // setFrames(framess)
  }
  /*eslint-disable*/
  useEffect(() => {
if(user?.userid){
  const fetchFramesData = async () => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/filter-workspaces/${DeptId}/${user?.userid}/`);
      const combinedFrames = [
        ...response.data.my_workspaces,
        ...response.data.public,
        ...response.data.shared
      ];

      setFrames(combinedFrames);
    } catch (error) {
      console.error('Error fetching frames:', error);
    }
  };

  fetchFramesData();
  setsidebartrue(!sidebartrue);
  fetchTags();
}
   
  }, [selectedTags,user?.userid]);
  /*eslint-enable*/

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowTagMenu(false);
      }



    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  console.log("framess", framess, frames)
  /*eslint-disable*/
  const fetchTags = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/tags/`);
      const data = await response.json();
      setTags(data?.tags?.map(tag => ({ value: tag.id, label: tag.tag_name })));
      setOptions(data?.tags?.map(tag => ({ value: tag.id, label: tag.tag_name })))
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };
  useEffect(() => {
    const fetchAndUpdate = () => {
      const DeptId = localStorage.getItem('HDept');
      const DivId = localStorage.getItem('HDiv');
      if (DeptId && DivId) {
        fetchFramesData()
        setsidebartrue(!sidebartrue);
        // dispatch(fetchFrames({ DeptId, DivId }));
      }
    };


    // Fetch frames initially
    if (status === 'idle') {
      fetchAndUpdate();
      fetchTags();

    }

    // Listen for storage changes
    const handleStorageChange = (event) => {
      if (event.key === 'DeptId' || event.key === 'DivId') {
        fetchAndUpdate();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup event listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch, status, DeptId, DivId]);
  /*eslint-enable*/

  const handleFilterClick = (tagId) => {
    if (tagId === 'clear') {
        // Call fetchFramesData when "Clear Filter" is clicked
        setsidebartrue(!sidebartrue);
        setShowTagMenu(false); // Hide the menu after selection
        return;
    }
    
    const DeptId = localStorage.getItem('HDept');
    // const DivId = localStorage.getItem('HDiv');
    const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/filter-workspace-tag/${user?.userid}/${DeptId}/${tagId}/`;
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        const combinedFrames = [
          ...data.my_workspaces,
          ...data.public,
          ...data.shared
        ];
        setFrames(combinedFrames);
      })
      .catch(error => console.error('Error:', error));
    setShowTagMenu(false); // Hide the menu after selection
};

  const deleteFrameSubmit = async (id) => {
    try {
      await dispatch(deleteFrame(id)).then(() => {
        setsidebartrue(!sidebartrue);
      });

      console.log(`Frame with ID ${id} deleted successfully.`);
    } catch (error) {
      console.error(`Error deleting frame with ID ${id}:`, error);
    }
  };
  const handleDeleteClick = (id) => {
    setDeletingFrameId(id); // Store the frame ID to delete
    setShowConfirmation(true); // Show confirmation dialog
  };


  const handleConfirmDelete = () => {
    deleteFrameSubmit(deletingFrameId); // Call delete function with stored frame ID
    setShowConfirmation(false); // Close confirmation dialog
  };


  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('workspace_name', frameName);
    formData.append('privacy_type', privacyType);
    formData.append('description', description);
    formData.append('user_id', user?.userid);
    formData.append('department_id', dept);
    formData.append('division_id', Div);


    formData.append('icon', iconFile);
    formData.append('tags', JSON.stringify(selectedTags?.map(tag => tag.value)));

// console.log("formDatatttttttttttt",formData)
    try {
      await dispatch(addFrame(formData)).then(() => {
        handleClose(); 
        setsidebartrue(!sidebartrue);
        setFrameName('');
        setPrivacyType('');
        setDescription('');
        setIconFile(null);
       
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case 'sharable':
        return <AiOutlineShareAlt className="type-icon" />;
      case 'private':
        return <AiOutlineLock className="type-icon" />;
      case 'public':
        return <AiOutlineGlobal className="type-icon" />;
      default:
        return null;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleEditModalShow = (id) => {
    const frameToEdit = frames.find((frame) => frame.id === id);
    if (frameToEdit) {
      setFrameId(frameToEdit.id);
      setFrameName(frameToEdit.workspace_name);
      setPrivacyType(frameToEdit.privacy_type);
      setDescription(frameToEdit.description);
      setIconFile(frameToEdit?.icon);
      setexistingIconFile(frameToEdit?.icon)
      setSelectedTags(frameToEdit?.tags?.map(tag => ({ value: tag.id, label: tag.tag_name })));

      setShowEditModal(true);
    }
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setFrameId(null);
    setFrameName('');
    setPrivacyType('');
    setDescription('');
    setIconFile(null);
  };

  const handleEdit = (editedOption, newLabel) => {
    const updatedOptions = options.map((option) =>
      option.value === editedOption.value
        ? { ...option, label: newLabel }
        : option
    );
    setOptions(updatedOptions);

    const updatedSelectedOptions = selectedOptions.map((option) =>
      option.value === editedOption.value
        ? { ...option, label: newLabel }
        : option
    );
    setSelectedOptions(updatedSelectedOptions);
  };
  const handleEditSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('workspace_name', frameName);
    formData.append('privacy_type', privacyType);
    formData.append('description', description);
    formData.append('user_id', user?.userid);
    formData.append('id', frameId);
    formData.append('department_id', dept);
    formData.append('division_id', Div);

    if (iconFile && iconFile !== existingIconFile) {
      formData.append('icon', iconFile);
    }
    formData.append('tags', JSON.stringify(selectedTags.map(tag => tag.value)));

    try {
      await dispatch(updateFrame(formData)).then(() => {
        handleEditModalClose();
        setsidebartrue(!sidebartrue);
        setFrameName('');
        setPrivacyType('');
        setDescription('');
        setIconFile(null);
      });
      // await dispatch(fetchFrames()); 
     
      // window.location.reload()
      // 

      // window.location.reload()
      // dispatch(fetchFrames());
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleChange = (selectedOptions) => {
    setSelectedTags(selectedOptions)
    console.log(selectedOptions);
  };

  const AddTag = async (payload) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/add-tag/`, payload);
      
      if (response) {
        // Call fetchTags() after a successful API response
        fetchTags();
      }
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };
  
  const handleCreate = (inputValue) => {
   
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
    // setOptions((prevOptions) => [...prevOptions, newOption]);
    // console.log("asnnsdsd")
    let payloadData =  {"tag_name": newOption.value}
    AddTag(payloadData)
  };
  const EditableOption = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(props.data.label);

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.key === 'Escape') {
        setIsEditing(false);
        if (event.key === 'Enter') {
          handleEdit(props.data, inputValue);
        }
      }
    };

    return (
      <components.Option {...props}>
        {isEditing ? (
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={() => setIsEditing(false)}
            autoFocus
          />
        ) : (
          <div onDoubleClick={() => setIsEditing(true)}>
            {props.data.label}
          </div>
        )}
      </components.Option>
    );
  };
  console.log("tagstagsiuy", tags)
  return (
    <div className="main-frames-container dashboard-container">
<div className="header" style={{ position: "relative" }}>
  <h2>Work Spaces List</h2>
  <div style={{ display: "flex", alignItems: "center" }}>
    <AiOutlineFilter
      className="filter-icon mx-4 cursor-pointer"
      style={{ width: "45px", height: '45px', cursor: "pointer" }}
      onClick={() => setShowTagMenu(!showTagMenu)}
      title='Filter'
    />
    <Button variant="primary" onClick={handleShow}>
      Create Work Space
    </Button>
    {showTagMenu && (
  <div className="add-column-menuu" ref={menuRef}>
    {tags?.map(tag => (
      <div
        className='menu-item'
        style={{ color: 'black' }}
        key={tag.value}
        onClick={() => handleFilterClick(tag.value)}
      >
        <span style={{ color: 'black !important' }}>{tag?.label}</span>
      </div>
    ))}
    <div
      className='menu-item'
      style={{ color: 'black' }}
      onClick={() => handleFilterClick('clear')}
    >
      <span style={{ color: 'black !important' }}>Clear Filter</span>
    </div>
  </div>
)}
  </div>
</div>

      <div className="frames-grid ">

        {frames?.map((frame) => (
          <Card key={frame.id} className="frame-card">

            {frame?.user_id === user?.userid ? <div className="edit-icon-container">
              
              <AiOutlineEdit
                className="edit-icon"
                onClick={() => handleEditModalShow(frame.id)}
              />
              {/* <AiOutlineDelete
                className="delete-icon"
                onClick={() => deleteFrameSubmit(frame.id)}
              /> */}
              <AiOutlineDelete className="edit-icon" onClick={() => handleDeleteClick(frame.id)} />

            </div> : ''}

            <div className="frame-details">
              <Row className="align-items-center">
                <Col xs={3} className="icon-col">
                  <div className="image-container">
                    <img
                      src={`${process.env.REACT_APP_API_ROOT}/${frame?.icon}`}
                      alt="Work Space Icon"
                      className="main-frame-icon"
                    />
                    <div className="type-icon-container">
                      {getIcon(frame?.privacy_type)}
                    </div>
                  </div>
                </Col>
                <Col xs={9} className="text-col">
                  <h5 className="frame-title">{frame.workspace_name}

                  </h5>
                  <span className="frame-date">
                    Created on: {formatDate(frame.created_at)} |
                    <span className="created-by-label">
                      Created by:
                      {frame?.user_details?.studid === user?.studid ? (
                        <>
          {user?.name.substring(0, 10)}...


                          <span className="badge badge-owner">
                            <img src="/King.png" alt="King Badge" className="owner-icon" width='20' height='20' />
                            {/* <i className="bi bi-person-badge-fill owner-icon"></i> */}
                          </span>
                        </>
                      ) : (
                        frame?.user_details?.name
                      )}
                    </span>
                  </span>


                  <p className="frame-tags">
                    {frame?.tags?.map(tag => `#${tag.tag_name} `)}
                    {frame?.sheets !== 0 ?
                      <div className="sheet-count-container">
                        sheets: {frame?.sheets || 0}
                      </div> : ''}

                  </p>
                  {/* <p className="frame-date">#business #strategy #management</p> */}
                </Col>
              </Row>
            </div>
          </Card>
        ))}
      </div>

      <Modal show={showModal} onHide={handleClose} centered>
  <Modal.Header closeButton>
    <Modal.Title>Create Work Space</Modal.Title>
  </Modal.Header>
  <Form onSubmit={handleSubmit}>
    <Modal.Body>
      {/* Work Space Name */}
      <Form.Group controlId="frameName">
        <Form.Label>
          Work Space Name <span className="mandatory">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter frame name"
          maxLength={100}
          value={frameName}
          onChange={(e) => setFrameName(e.target.value)}
          required
        />
      </Form.Group>

      {/* Privacy Type */}
      <Form.Group controlId="privacyType" className="mt-4">
        <Form.Label>
          Privacy Type <span className="mandatory">*</span>
        </Form.Label>
        <div>
          <Form.Check
            inline
            label="Private"
            name="privacyType"
            type="radio"
            id="private"
            value="private"
            checked={privacyType === 'private'}
            onChange={(e) => setPrivacyType(e.target.value)}
            required
          />
 {user?.role === 'super admin' ? (
          // Directly show the enabled option if the user is a super admin
          <Form.Check
            inline
            label="Public"
            name="privacyType"
            type="radio"
            id="public"
            value="public"
            checked={privacyType === 'public'}
            onChange={(e) => setPrivacyType(e.target.value)}
            required
          />
        ) : (
          // Show the OverlayTrigger with tooltip if the user is not a super admin
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>You need Super Admin privileges to select Public</Tooltip>}
          >
            <span className="d-inline-block">
              <Form.Check
                inline
                label="Public"
                name="privacyType"
                type="radio"
                id="public"
                value="public"
                checked={privacyType === 'public'}
                onChange={(e) => setPrivacyType(e.target.value)}
                required
                disabled
              />
            </span>
          </OverlayTrigger>
        )}

        {/* External Privacy Type */}
        {user?.role === 'super admin' ? (
          // Directly show the enabled option if the user is a super admin
          <Form.Check
            inline
            label="External"
            name="privacyType"
            type="radio"
            id="sharable"
            value="sharable"
            checked={privacyType === 'sharable'}
            onChange={(e) => setPrivacyType(e.target.value)}
            required
          />
        ) : (
          // Show the OverlayTrigger with tooltip if the user is not a super admin
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>You need Super Admin privileges to select External</Tooltip>}
          >
            <span className="d-inline-block">
              <Form.Check
                inline
                label="External"
                name="privacyType"
                type="radio"
                id="sharable"
                value="sharable"
                checked={privacyType === 'sharable'}
                onChange={(e) => setPrivacyType(e.target.value)}
                required
                disabled
              />
            </span>
          </OverlayTrigger>
        )}
      
        </div>
      </Form.Group>

      {/* Description */}
      <Form.Group controlId="description" className="mt-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>

      {/* Tags */}
      <Form.Group controlId="tags" className="mt-3">
        <Form.Label>Tags</Form.Label>
        <CreatableSelect
          isMulti
          options={options}
          onChange={handleChange}
          onCreateOption={handleCreate}
          components={{ Option: EditableOption }}
        />
      </Form.Group>

      {/* Icon Upload */}
      <Form.Group controlId="iconUpload" className="mt-3">
        <Form.Label>
          Work Space Icon <span className="mandatory">*</span>
        </Form.Label>
        <div className="d-flex align-items-center">
          <Form.Control
            type="file"
            accept="image/*"
            className="me-3"
            required
            onChange={(e) => setIconFile(e.target.files[0])}
          />
        </div>
      </Form.Group>
    </Modal.Body>

    {/* Footer */}
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Modal.Footer>
  </Form>
</Modal>

      <ConfirmationPopup
        isOpen={showConfirmation}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        isLoading={false} // You can set this to true when deleting
      />
      <Modal show={showEditModal} onHide={handleEditModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Work Space</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleEditSubmit}>
          <Modal.Body>
            <Form.Group controlId="frameName">
              <Form.Label>
                Work Space Name <span className="mandatory">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter frame name"
                value={frameName}
                maxLength={100}
                onChange={(e) => setFrameName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="privacyType" className="mt-4">
              <Form.Label>
                Privacy Type <span className="mandatory">*</span>
              </Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Private"
                  name="privacyType"
                  type="radio"
                  id="private"
                  value="private"
                  checked={privacyType === 'private'}
                  onChange={(e) => setPrivacyType(e.target.value)}
                  required
                />
                <Form.Check
                  inline
                  label="Public"
                  name="privacyType"
                  type="radio"
                  id="public"
                  value="public"
                  checked={privacyType === 'public'}
                  onChange={(e) => setPrivacyType(e.target.value)}
                  required
                />
                <Form.Check
                  inline
                  label="Sharable"
                  name="privacyType"
                  type="radio"
                  id="sharable"
                  value="sharable"
                  checked={privacyType === 'sharable'}
                  onChange={(e) => setPrivacyType(e.target.value)}
                  required
                />
              </div>
            </Form.Group>

            <Form.Group controlId="description" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="tags" className="mt-3">
              <Form.Label>Tags</Form.Label>
              <Select
                isMulti
                options={tags}
                value={selectedTags}
                onChange={(selected) => setSelectedTags(selected)}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Form.Group>
            <Form.Group controlId="iconUpload" className="mt-3">
              <Form.Label>Work Space Icon</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="file"
                  accept="image/*"
                  className="me-3"
                  onChange={(e) => setIconFile(e.target.files[0])}
                  
                />
                {/* <Button variant="secondary">Pick an Icon</Button> */}
              </div>
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditModalClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default MainFramesList;
