import React, { useState, useEffect, useRef } from 'react';
import { Link,useNavigate, useLocation } from 'react-router-dom';

import { Nav, Modal, Button, Form, InputGroup, Row, Col, Container, Spinner } from 'react-bootstrap';
import { BiInfoCircle, BiShareAlt } from 'react-icons/bi';
import { BiPencil, BiTrash,BiPlus } from 'react-icons/bi';
// import { AiOutlineShareAlt, AiOutlineLock, AiOutlineEdit, AiOutlineDelete, AiOutlineGlobal } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { components } from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { BsSun, BsMoon, BsFillShareFill, BsSearch ,BsX} from 'react-icons/bs';
// import { IoOpenOutline } from "react-icons/io5";
import '../../assets/styles/Sidebar.css';
import axios from 'axios';
import { deleteFrame, deleteSheet, fetchFrames, updateFrame } from '../../features/workspaces/workspaceActions';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationPopup from './ConfirmationPopup';
import { AiOutlineShareAlt, AiOutlineLock, AiOutlineEdit, AiOutlineGlobal, AiOutlineFileAdd } from 'react-icons/ai';
import { setValue } from '../../features/Department/departmentReducer';
import { setDivision } from '../../features/Division/divisionReducer';
import { useTheme } from './ThemeContext';
import ImportSheetModal from './ImportSheetModal';
import ShareWorkspaceModal from './ShareWorkspaceModal';
import ShareSheetModal from './ShareSheetModal';
const Sidebar = ({  user,maindeptName,sidebartrue,setsidebartrue }) => {  const [isFramesOpen, setIsFramesOpen] = useState(true);
  const [openFrames, setOpenFrames] = useState({});
  // const [isDarkMode, setIsDarkMode] = useState(false);
  const [openMenuFrameId, setOpenMenuFrameId] = useState(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSharePopupVisible, setIsSharePopupVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [sheetName, setSheetName] = useState('');
  const [privacyType, setPrivacyType] = useState('public');
  const [isSheetPopupVisible, setIsSheetPopupVisible] = useState(false);
  const [isUpdateSheetPopupVisible, setIsUpdateSheetPopupVisible] = useState(false);
  const [currentSheet, setCurrentSheet] = useState(null);
// const[workspaceId,setWorkspaceId]=useState()
const [tags, setTags] = useState([]);

  const [managementType, setManagementType] = useState('budgets');
  const [sheets, setSheets] = useState([]);
  const [openMenuSheetId, setOpenMenuSheetId] = useState(null);
const [isSheetMenuVisible, setIsSheetMenuVisible] = useState(false);
const [customManagementType, setCustomManagementType] = useState('');
const [showConfirmation, setShowConfirmation] = useState(false);
  const [deletingFrameId, setDeletingFrameId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [frameName, setFrameName] = useState('');
  // const [privacyType, setPrivacyType] = useState('');
  const [description, setDescription] = useState('');
  const [iconFile, setIconFile] = useState(null);
  const [frameId, setFrameId] = useState(null);
  // const framesData = useSelector((state) => state.frames.frames);
  // const frames = useSelector((state) => state.frames.frames);
  const status = useSelector((state) => state.frames.status);
  const [showModal, setShowModal] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [isDepartmentView, setIsDepartmentView] = useState(true);
  const[departmentName,setDepartmentName]=useState('')
  const[divisionName,setDivisionName]=useState('')
  /*eslint-disable*/
  const [selectedDivision, setSelectedDivision] = useState(null);
    /*eslint-enable*/
    const [modalShow, setModalShow] = useState(false);
    const [loadings, setLoadings] = useState(false); 
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  const [filteredDepartments, setFilteredDepartments] = useState(departments);
  const [filteredDivisions, setFilteredDivisions] = useState(divisions);
  const [searchTerm, setSearchTerm] = useState('');
  const [originalDepartments, setOriginalDepartments] = useState(departments);
  const [originalDivisions, setOriginalDivisions] = useState(divisions);
  const { isDarkMode, toggleTheme } = useTheme();
  const [menuVisible, setMenuVisible] = useState(false);
  const [deletingSheetId, setDeletingSheetId] = useState(null);
  const [showSheetConfirmation, setShowSheetConfirmation] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ctps, setCtps] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [selectedCtpId, setSelectedCtpId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [existingIconFile,setexistingIconFile]=useState()
  const [selectedTags, setSelectedTags] = useState([]);
  const [options, setOptions] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [sheetIdToShare, setSheetIdToShare] = useState(null);
  const [frameSheets, setFrameSheets] = useState({}); // Store sheets data by frame ID

  const [selectedOptions, setSelectedOptions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (isModalVisible) {
      fetchCtps();
    }
  }, [isModalVisible]);

  const fetchCtps = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/all-ctp/`);
      setCtps(response.data.ctps);
    } catch (error) {
      setError('Failed to load CPTs');
    } finally {
      setLoading(false);
    }
  };
  const handleShareSheetClick = (sheetId) => {
    // Open the share modal and pass the sheetId to it
    setShowShareModal(true);
    setSheetIdToShare(sheetId); // Assuming you have a state to hold the sheetId
  };
  const handleDropdownChange = (event) => {
    const course = event.target.value;
    setSelectedSymbol(course);
    const selectedCtp = ctps.find(c => c.course === course);
    setSelectedCtpId(selectedCtp ? selectedCtp.ctpid : null);
  };
  const fetchTags = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/tags/`);
      const data = await response.json();
      setTags(data?.tags?.map(tag => ({ value: tag.id, label: tag.tag_name })));
      setOptions(data?.tags?.map(tag => ({ value: tag.id, label: tag.tag_name })))
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };
  const handleSubmit = async () => {
    setLoadings(true); // Start loading spinner

    console.log("selectedCtpId",selectedCtpId,frameId)
    if (!selectedCtpId || !openMenuFrameId) return;
    try {
      await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/create-blueprint-sheet/`, {
        ctpid: selectedCtpId,
        workspace_id: openMenuFrameId,
        user_id:user?.userid
      });
      setIsModalVisible(false);
      toast.success('Blue print Created successfully!');
      setLoadings(false); // Stop loading spinner
      fetchSheets();
// setsidebartrue(!sidebartrue)

    } catch (error) {
      setError('Failed to submit blueprint');
    }
  };

  const[frames,setFrames]=useState([])
// const TOS_PATH="http://localhost/latest/TOS"
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  React.useEffect(() => {
    document.body.className = isDarkMode ? 'dark-mode' : 'light-mode';
  }, [isDarkMode]);

  const DeptId=localStorage.getItem("HDept")||1
    const DivId=localStorage.getItem("HDiv")||1
    const DeptName=localStorage.getItem("HDeptName")||"Driving School"
    const DivName=localStorage.getItem("HDivName")||"devision 1 fngeigbeligh vk"
  // Update originalDepartments when the departments prop changes
  useEffect(() => {
    setOriginalDepartments(departments);
  }, [departments]);
  const menuRef = useRef(null);
  const dispatch=useDispatch();
  useEffect(() => {

    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false)  

        setIsMenuVisible(false);
        setIsSheetMenuVisible(false)
          }
        
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
    /*eslint-disable*/

  useEffect(() => {
    if (status === 'idle') {
      // dispatch(fetchFrames());
      if(DeptId &&DivId){
        fetchFramesData()
        setDepartmentName(DeptName)
        setDivisionName(DivName)
        fetchTags();


      }
      const dept=localStorage.getItem("HDept")||1
    const Div=localStorage.getItem("HDiv")||1
    console.log("kjhgfdxszsdfghjk")
    
    }
  }, [dispatch, status,DeptId,DivId,user?.userid,sidebartrue]);
  useEffect(() => {
    if (status === 'idle') {
      fetchFramesData()
      fetchTags();
    }
  }, [dispatch, status, DeptId, DivId]);
  

    /*eslint-enable*/
  const fetchFramesData = async () => {
    if(DeptId &&DivId && user?.userid){
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/filter-workspaces/${DeptId}/${user?.userid}/`);
      if (response?.data) {
const combinedFrames = [
...response.data.my_workspaces,
...response.data.public,
...response.data.shared
];

setFrames(combinedFrames);        }
  } catch (error) {
      console.error('Error fetching status options:', error);
  }
}
    // setFrames(framess)
  }
  const fetchFramesDataDivision = async (deptid,divid) => {
    if(deptid && divid && user?.userid){
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/filter-workspaces/${deptid}/${user?.userid}/`);
      if (response?.data) {
const combinedFrames = [
...response.data.my_workspaces,
...response.data.public,
...response.data.shared
];

setFrames(combinedFrames);        }
  } catch (error) {
      console.error('Error fetching status options:', error);
  }
}
    // setFrames(framess)
  }
  // useEffect(() => {
  //   const dept=localStorage.getItem("HDept")||1
  //   const Div=localStorage.getItem("HDiv")||1
  //   if( deptName && divName){
  //     setDepartmentName(deptName)
  //     setDivisionName(divName)
  //     setSelectedDepartment(dept)
  //     setSelectedDivision(Div)
  //   }
    
  // }, []);

  const deleteFrameSubmit = async (id) => {
    try {
      await dispatch(deleteFrame(id));
      setsidebartrue(!sidebartrue)
      console.log(`Frame with ID ${id} deleted successfully.`);
    } catch (error) {
      console.error(`Error deleting frame with ID ${id}:`, error);
    }
  };
  const handleDeleteClick = (id) => {
    setDeletingFrameId(id); // Store the frame ID to delete
    setShowConfirmation(true); // Show confirmation dialog
  };
  

  const handleConfirmDelete = () => {
    deleteFrameSubmit(deletingFrameId); // Call delete function with stored frame ID
    setShowConfirmation(false); // Close confirmation dialog
  };
  

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };
  const handleShareWorkspaceClick = () => {
    setIsShareModalVisible(true); // Show the modal when "Share" is clicked
  };

  const handleCloseWorkspaceModal = () => {
    setIsShareModalVisible(false); // Hide the modal when closed
  };




  const deleteSheetSubmit = async (id) => {
    try {
      await dispatch(deleteSheet(id));
      toast.success('Sheet deleted successfully!');
      setsidebartrue(!sidebartrue)
      dispatch(fetchFrames());
      fetchSheets()
      // window.location.reload()
      console.log(`Frame with ID ${id} deleted successfully.`);
    } catch (error) {
      console.error(`Error deleting frame with ID ${id}:`, error);
    }
  };
  const handleDeleteSheet = (id) => {
    setDeletingSheetId(id); // Store the frame ID to delete
    setShowSheetConfirmation(true); // Show confirmation dialog
  };
  

  const handleConfirmSheetDelete = () => {
    deleteSheetSubmit(deletingSheetId); // Call delete function with stored frame ID
    setShowSheetConfirmation(false); // Close confirmation dialog
  };
  

  const handleCancelSheetDelete = () => {
    setShowSheetConfirmation(false);
  };
  const handleShare = async () => {
    const payload = selectedUsers?.map((selectedUser) => ({
      workspace_id: openMenuFrameId,
      shared_by: user?.userid,
      shared_with: selectedUser.id,
    }));

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/share-workspace/`, payload);
      console.log('Share response:', response.data);
      setIsSharePopupVisible(false);
      setSelectedUsers([])
    } catch (error) {
      console.error('Error sharing workspace:', error);
    }
  };

  const toggleFrames = () => {
    setIsFramesOpen(!isFramesOpen);
  };
  const handleSheetMenuClick = (sheetId) => {
    setOpenMenuSheetId(sheetId);
    console.log("isSheetMenuVisible",isSheetMenuVisible)
    setIsSheetMenuVisible(true);
  };
  
  // const handleEditSheetClick = (sheetId) => {
  //   // Logic for editing the sheet
  //   console.log(`Editing sheet ${sheetId}`);
  // };
  
  // const handleDeleteSheet = (sheetId) => {
  //   // Logic for deleting the sheet
  //   console.log(`Deleting sheet ${sheetId}`);
  // };
  
 
  const handleCreateSheetClick = () => {
    setIsSheetPopupVisible(true);
  };
  const handleEditClick = (sheet) => {
    console.log("sheeeeeet",sheet)
    setCurrentSheet(sheet);
    setSheetName(sheet.name);
    setPrivacyType(sheet.privacy_type);
    setManagementType(sheet.managementType);
    setIsUpdateSheetPopupVisible(true);
  };
  const handleUpdateSheetSubmit = () => {
    const payload = {
      name: sheetName,
      privacy_type: privacyType,
      workspace_id:currentSheet.workspace_id,
      user:user?.userid,
      // managementType: managementType
    };

    axios.put(`${process.env.REACT_APP_API_ROOT}/hotwash/sheets/${currentSheet.id}/`, payload)
      .then(response => {
        setSheets(sheets.map(sheet => sheet?.id === currentSheet?.id ? response.data : sheet));
        setIsUpdateSheetPopupVisible(false);
         setSheetName('');
      setCustomManagementType('')
      setManagementType('')
      })
      .catch(error => {
        console.error('There was an error updating the sheet!', error);
      });
  };
  const fetchSheets = async () => {
    // try {
    //   const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/sheets/`);
    //   setSheets(response.data);
    // } catch (error) {
    //   console.error('Error fetching sheets:', error);
    // }
  };
    /*eslint-disable*/

  const handleCreateSheetSubmit = async () => {
    setLoadings(true)
    try {
      const payload = {
        workspace_id: openMenuFrameId,
        name: sheetName,
        privacy_type: privacyType,
        user:user?.userid,
      };
      const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/sheets/`, payload);
      console.log('Sheet created:', response.data);
      const sheetId = response.data.id;

      const secondApiResponse = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/add-group-heders/`, {
        sheet_id: sheetId,
        name: managementType == 'custom' ? customManagementType : managementType,
      });
      console.log('Added group headers:', secondApiResponse.data);

      setIsSheetPopupVisible(false);
      setSheetName('');
      setCustomManagementType('')
      setManagementType('')
      setPrivacyType('public');
      // fetchSheets();

      setLoadings(false)
      await fetchSheetsForFrame(openMenuFrameId);


    } catch (error) {
      console.error('Error creating sheet:', error);
    }
  };

  // const toggleTheme = () => {
  //   setIsDarkMode(!isDarkMode);
  // };

  // const handleAddMenuClick = (frameId) => {
  //   setOpenMenuFrameId(openMenuFrameId === frameId ? null : frameId);
  //   setIsMenuVisible(true);
  // };
  // Shared function to fetch sheets based on frame ID
  const fetchSheetsForFrame = async (frameId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/workspace-sheets/${user.userid}/${frameId}/`);
      
      if (response?.data?.sheets) {
        setFrameSheets((prevSheets) => ({
          ...prevSheets,
          [frameId]: response.data.sheets, // Store sheets for the specific frame
        }));
      }
    } catch (error) {
      console.error('Error fetching sheets:', error);
    }
  };
  
  const toggleFrame = async (id) => {
    // Fetch the sheets for the clicked frame
    await fetchSheetsForFrame(id);
  
    // Toggle the open state for the clicked frame
    setOpenFrames((prevOpenFrames) => ({
      ...prevOpenFrames,
      [id]: !prevOpenFrames[id],
    }));
  };
// Handler for opening the three-dots menu (vertical menu)
const handleAddMenuClick = async (frameId) => {
  // Fetch sheets when clicking on the menu as well
  await fetchSheetsForFrame(frameId);

  // Toggle the menu visibility for the frame
  setOpenMenuFrameId(openMenuFrameId === frameId ? null : frameId);
  setIsMenuVisible(true);
};


  
  const handleShareClick = () => {
    setIsSharePopupVisible(true);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/sch/get_user_data/${DeptId}/`);
        const data = await response.json();
        const fetchedUsers = data?.user_data;
        const filteredUsers = fetchedUsers.filter(usercheck => usercheck.id != user?.userid);
        setUsers(filteredUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
    fetchSheets();

  }, [user]);
  /*eslint-enable*/

  // useEffect(() => {
  //   const fetchSheets = async () => {
  //     try {
  //       const response = await axios.get('${process.env.REACT_APP_API_ROOT}/hotwash/sheets/');
  //       setSheets(response.data);
  //     } catch (error) {
  //       console.error('Error fetching sheets:', error);
  //     }
  //   };

  // }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleUserSelect = (user) => {
    if (!selectedUsers.some((u) => u.id === user.id)) {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleRemoveUser = (userId) => {
    setSelectedUsers(selectedUsers.filter((user) => user.id !== userId));
  };

  // const filteredUsers = users.filter(user =>
  //   user.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  function getUserRoleClass(role) {
    switch (role) {
      case 'super admin':
        return 'super-admin';
      case 'instructor':
        return 'instructor';
      case 'student':
        return 'student';
      case 'phase manager':
        return 'phase-manager';
      case 'course manager':
        return 'course-manager';
      default:
        return 'default-role';
    }
  }
  const getIcon = (type) => {
    switch (type) {
      case 'sharable':
        return <AiOutlineShareAlt className="type-icon" />;
      case 'private':
        return <AiOutlineLock className="type-icon" />;
      case 'public':
        return <AiOutlineGlobal className="type-icon" />;
      default:
        return null;
    }
  };
  const handleEditModalShow = (id) => {
    const frameToEdit = frames.find((frame) => frame.id === id);
    if (frameToEdit) {
      setFrameId(frameToEdit.id);
      setFrameName(frameToEdit.workspace_name);
      setPrivacyType(frameToEdit.privacy_type);
      setDescription(frameToEdit.description);
      setIconFile(frameToEdit?.icon);
      setexistingIconFile(frameToEdit?.icon)
      setSelectedTags(frameToEdit?.tags?.map(tag => ({ value: tag.id, label: tag.tag_name })));

      setShowEditModal(true);
    }
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setFrameId(null);
    setFrameName('');
    setPrivacyType('');
    setDescription('');
    setIconFile(null);
    setexistingIconFile('')
  };
// const handleDepartment=()=>{
//   dispatch(setValue(2));

// dispatch(setDivision(3));

// }
const handleEdit = (editedOption, newLabel) => {
  const updatedOptions = options.map((option) =>
    option.value === editedOption.value
      ? { ...option, label: newLabel }
      : option
  );
  setOptions(updatedOptions);

  const updatedSelectedOptions = selectedOptions.map((option) =>
    option.value === editedOption.value
      ? { ...option, label: newLabel }
      : option
  );
  setSelectedOptions(updatedSelectedOptions);
};
  const handleEditSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('workspace_name', frameName);
    formData.append('privacy_type', privacyType);
    formData.append('description', description);
    formData.append('user_id', user?.userid);
    formData.append('id', frameId);
    formData.append('department_id', DeptId);
    formData.append('division_id', DivId);

    // if (iconFile) {
    //   formData.append('icon', iconFile);
    // }
    if (iconFile && iconFile !== existingIconFile) {
      formData.append('icon', iconFile);
    }
    formData.append('tags', JSON.stringify(selectedTags.map(tag => tag.value)));

    try {
      await dispatch(updateFrame(formData));
      handleEditModalClose();
      setsidebartrue(!sidebartrue)

      // dispatch(fetchFrames());
    } catch (error) {
      console.error('Error:', error);
    }
  };
  // const handleChange = (selectedOptions) => {
  //   setSelectedTags(selectedOptions)
  //   console.log(selectedOptions);
  // };

  const AddTag = async (payload) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/add-tag/`, payload);
      
      if (response) {
        // Call fetchTags() after a successful API response
        fetchTags();
      }
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };

    /*eslint-disable*/
  const handleCreate = (inputValue) => {
   
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
   
    let payloadData =  {"tag_name": newOption.value}
    AddTag(payloadData)
  };
  const EditableOption = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(props.data.label);

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.key === 'Escape') {
        setIsEditing(false);
        if (event.key === 'Enter') {
          handleEdit(props.data, inputValue);
        }
      }
    };
        /*eslint-enable*/

    return (
      <components.Option {...props}>
        {isEditing ? (
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={() => setIsEditing(false)}
            autoFocus
          />
        ) : (
          <div onDoubleClick={() => setIsEditing(true)}>
            {props.data.label}
          </div>
        )}
      </components.Option>
    );
  };
  const handleDepartment = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/departments/`);
      setDepartments(response.data.departments);
      setShowModal(true);
      setIsDepartmentView(true);
      // if (location.pathname !== '/') {
      //   navigate('/');
      // }
       // Show departments by default
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };
const handleDivison = async () => {
  setShowModal(true);
  setIsDepartmentView(false); // Show departments by default
  const DeptId = localStorage.getItem("HDept") || 1; // Get department ID from localStorage or fallback to 1
console.log("DeptIdjjjjjjjjjjj",DeptId)
  if (DeptId) {
    setSelectedDepartment(DeptId);
    if (location.pathname !== '/') {
      navigate('/');
    }
    await handleDivisionFetchDirectly(DeptId); // Pass DeptId instead of departmentData object
      dispatch(setValue(DeptId));
  }

}
const handleDivisionFetchDirectly = async (DeptId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/divisions/${DeptId}/`);
    if (response?.data?.divisions?.length === 0) {
      setDivisions([]);
      // setSelectedDepartment(departmentData?.id);
      // localStorage.setItem("HDeptName", departmentData?.department_name);

      // dispatch(setValue(departmentData?.id));

    } else {
      setDivisions(response.data.divisions);
      
    }
  } catch (error) {
    console.error('Error fetching divisions:', error);
  }
};
  const handleDivisionFetch = async (departmentData) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/divisions/${departmentData?.id}/`);
      if (response?.data?.divisions?.length === 0) {
        setDivisions([]);
        setSelectedDepartment(departmentData?.id);
        localStorage.setItem("HDeptName", departmentData?.department_name);
        if (location.pathname !== '/') {
          navigate('/');
        }
        setsidebartrue(!sidebartrue)

        dispatch(setValue(departmentData?.id));

        setDepartmentName(departmentData?.department_name)
        setIsDepartmentView(false); // Switch to division view
      } else {
        setDivisions(response.data.divisions);
        setSelectedDepartment(departmentData?.id);
        setDepartmentName(departmentData?.department_name)
        localStorage.setItem("HDeptName", departmentData?.department_name);
        if (location.pathname !== '/') {
          navigate('/');
        }
        setsidebartrue(!sidebartrue)
        
        setIsDepartmentView(false); // Switch to division view
      }
    } catch (error) {
      console.error('Error fetching divisions:', error);
    }
  };

  const handleDivisionSelect = (division) => {
    console.log('Selected Department:', selectedDepartment);
    console.log('Selected Division:', division.id);
    localStorage.setItem("HDept",selectedDepartment)
    localStorage.setItem("HDiv",division.id)
    localStorage.setItem("HDivName", division.department_name);

    // window.location.reload();
    setsidebartrue(!sidebartrue)
    setSelectedDivision(division.id)
    dispatch(setDivision(division?.id))
    fetchFramesDataDivision(selectedDepartment,division.id)

// setDepartmentName()
    setDivisionName(division?.department_name)
    setShowModal(false);
    setDivisions([]); // Reset divisions for next use
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsDepartmentView(true); // Reset to department view for next use
    setDivisions([]); // Reset divisions
  };
const handleCloseImportModal = () => {
  setModalShow(false)
  fetchSheets()
}
  const handleGoBack = () => {
    setIsDepartmentView(true);
    setDivisions([]);
  };
  const handleNavigation = (url) => {
    window.location.href = url;
  };

  // const handleSearch = (event) => {
  //   const searchTerm = event.target.value.toLowerCase();
  //   if (searchTerm === '') {
  //     setDepartments(departments); // Reset filter when searchTerm is empty
  //   } else {
  //     setDepartments(
  //       departments.filter(department =>
  //         department.department_name.toLowerCase().includes(searchTerm)
  //       )
  //     );
  //   }
  //   // Filter departments based on the search term
  //   // Assuming you have a way to get and set the departments state
   
  // };
  useEffect(() => {
    setOriginalDepartments(departments);
  }, [departments]);

  useEffect(() => {
    setOriginalDivisions(divisions);
  }, [divisions]);

  // Update filteredDepartments and filteredDivisions based on searchTerm
  useEffect(() => {
    if (isDepartmentView) {
      if (searchTerm === '') {
        setFilteredDepartments(originalDepartments);
      } else {
        setFilteredDepartments(
          originalDepartments.filter(department =>
            department.department_name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }
    } else {
      if (searchTerm === '') {
        setFilteredDivisions(originalDivisions);
      } else {
        setFilteredDivisions(
          originalDivisions.filter(division =>
            division.department_name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }
    }
  }, [searchTerm, originalDepartments, originalDivisions, isDepartmentView]);
  
  const handleCloseModalSheet = () => {
    // setIsUpdateSheetPopupVisible(false);  // Hide the modal
    setSheetName('');                     // Reset sheet name
    setCustomManagementType('');          // Reset custom management type
    setManagementType(''); 
    setIsSharePopupVisible(false)               // Reset management type
};
const handleCloseModalUpdateSheet = () => {
  setIsUpdateSheetPopupVisible(false);  // Hide the modal
  setSheetName('');                     // Reset sheet name
  setCustomManagementType('');          // Reset custom management type
  setManagementType(''); 
};
  // Handle search input change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
console.log("divisionName",divisionName)
  // Clear search input
  /*eslint-disable*/
  const handleClearSearch = () => {
    setSearchTerm('');  // This will trigger the useEffect to reset the filter
  };
    /*eslint-enable*/

  const getButtonWidth = (departmentName) => {
    const baseWidth = 100; // base width in pixels
    const charWidth = 8; // average character width in pixels
    return baseWidth + (departmentName.length * charWidth);
  };
  const renderTooltip = (message) => (
    <Tooltip id="tooltip-disabled">
      {message}
    </Tooltip>
  );

  // Enable actions only if both frame?.is_owner and frame?.is_admin are true
  
  // const staticPath = "upload/";

  // const imageUrl = `${staticPath}${user?.file_name}`;
  return (
    <div className="sidebar" >
       {/* <p onClick={handleDepartment}>Deprtments</p> */}
       {/* <p onClick={handleDepartment}></p> */}

      <Nav className="flex-column mx-2">
      <Nav.Link  onClick={handleDepartment} className="d-flex align-items-center justify-content-between  ">
          <span style={{ fontSize: '', color: '#00C9A7' }}>
           {maindeptName}/{departmentName}
          </span>
          
        </Nav.Link>

        <p className='mx-3' onClick={handleDivison} style={{backgroundColor: "#9aae0a",
    // width: "80%",
    color: "white",
    textAlign: "center",
    cursor: "pointer"}}>{divisionName}</p>
<hr className='mx-3' style={{color:"black", marginTop:"-5px"}}/>

        <Nav.Link as={Link} to="/" onClick={toggleFrames} className="d-flex align-items-center justify-content-between">
          <span>
            <i className="bi bi-house-door"></i> All Work Spaces
          </span>
          <i className={`bi ${isFramesOpen ? 'bi-chevron-down' : 'bi-chevron-up'}`}></i>
        </Nav.Link>
        {isFramesOpen &&
  frames?.map((frame) => (
    <div key={frame.id}>
      <Nav.Link className="d-flex align-items-center justify-content-between ms-3">
        <span className="d-flex align-items-center" onClick={() => toggleFrame(frame.id)}>
          {frame?.icon ? (
            <div className="image-containerr">
              <img
                src={`${process.env.REACT_APP_API_ROOT}/${frame?.icon}`}
                alt="Work Space Icon"
                className="main-frame-iconn"
              />
              <div className="type-icon-containerr">
                {getIcon(frame?.privacy_type)}
              </div>
            </div>
          ) : (
            <i className="bi bi-list me-2"></i>
          )}
          <span className="frames-name mx-2">{frame.workspace_name}</span>
        </span>
        <div className="add-column-button-container">
          <i className="bi bi-three-dots-vertical" onClick={() => handleAddMenuClick(frame.id)}></i>
          {openMenuFrameId === frame.id && isMenuVisible && (
  <div className="add-column-menu frame-name" ref={menuRef}> 

    {/* Add Sheet */}
    {!(frame?.is_owner || frame?.is_admin) ? (
      <OverlayTrigger
        placement="top"
        overlay={renderTooltip("You need to be an admin to perform this action")}
      >
        <p className="menu-item" style={{ pointerEvents: 'none', color: 'gray' }}>
          <BiPlus className="me-2" /> Add Sheet
        </p>
      </OverlayTrigger>
    ) : (
      <p className="menu-item" onClick={handleCreateSheetClick}>
        <BiPlus className="me-2" /> Add Sheet
      </p>
    )}

    {/* Import Sheet */}
    {!(frame?.is_owner || frame?.is_admin) ? (
      <OverlayTrigger
        placement="top"
        overlay={renderTooltip("You need to be an admin to perform this action")}
      >
        <p className="menu-item" style={{ pointerEvents: 'none', color: 'gray' }}>
          <BiPlus className="me-2" /> Import Sheet
        </p>
      </OverlayTrigger>
    ) : (
      <p className="menu-item" onClick={() => setModalShow(true)}>
        <BiPlus className="me-2" /> Import Sheet
      </p>
    )}

    {/* Edit */}
    {!(frame?.is_owner || frame?.is_admin) ? (
      <OverlayTrigger
        placement="top"
        overlay={renderTooltip("You need to be an admin to perform this action")}
      >
        <p className="menu-item" style={{ pointerEvents: 'none', color: 'gray' }}>
          <AiOutlineEdit className="me-2" /> Edit
        </p>
      </OverlayTrigger>
    ) : (
      <p className="menu-item" onClick={() => handleEditModalShow(frame.id)}>
        <AiOutlineEdit className="me-2" /> Edit
      </p>
    )}

    {/* Delete */}
    {!(frame?.is_owner || frame?.is_admin) ? (
      <OverlayTrigger
        placement="top"
        overlay={renderTooltip("You need to be an admin to perform this action")}
      >
        <p className="menu-item" style={{ pointerEvents: 'none', color: 'gray' }}>
          <BiTrash className="me-2" /> Delete
        </p>
      </OverlayTrigger>
    ) : (
      <p className="menu-item" onClick={() => handleDeleteClick(frame.id)}>
        <BiTrash className="me-2" /> Delete
      </p>
    )}

    {/* Create Blueprint */}
    {!(frame?.is_owner || frame?.is_admin) ? (
      <OverlayTrigger
        placement="top"
        overlay={renderTooltip("You need to be an admin to perform this action")}
      >
        <p className="menu-item" style={{ pointerEvents: 'none', color: 'gray' }}>
          <AiOutlineFileAdd className="me-2" /> Create Blueprint
        </p>
      </OverlayTrigger>
    ) : (
      <p className="menu-item" onClick={() => setIsModalVisible(true)}>
        <AiOutlineFileAdd className="me-2" /> Create Blueprint
      </p>
    )}

    {/* Share Workspace */}
    {frame?.privacy_type === 'private' && (
      !(frame?.is_owner || frame?.is_admin) ? (
        <OverlayTrigger
          placement="top"
          overlay={renderTooltip("You need to be an admin to perform this action")}
        >
          <p className="menu-item" style={{ pointerEvents: 'none', color: 'gray' }}>
            <BsFillShareFill className="me-2" /> Share
          </p>
        </OverlayTrigger>
      ) : (
        <p className="menu-item" onClick={handleShareWorkspaceClick}>
          <BsFillShareFill className="me-2" /> Share
        </p>
      )
    )}

  </div>
)}

        </div>
      </Nav.Link>
      <ShareWorkspaceModal
                showModal={isShareModalVisible}
                handleClose={handleCloseWorkspaceModal}
                workspaceId={openMenuFrameId}  // Pass the workspace ID
                sharedById={user?.userid}    // Pass the shared_by ID (assuming it's globally available)
                users={users}            // Pass the user list
        
      />
      {openFrames[frame.id] && frameSheets[frame.id]?.length > 0 && frameSheets[frame.id].map(sheet => (
  <div key={sheet?.id} className="sheet-container ms-5 d-flex align-items-center justify-content-between">
    <Nav.Link as={Link} to={`/frame/${frame.id}/${sheet.name}/${sheet.id}`} className="d-flex align-items-center">
      <div className="image-containerr">
        <img
          src={`${process.env.REACT_APP_API_ROOT}/${frame?.icon}`}
          alt="Workspace Icon"
          className="main-frame-icons"
        />
        <div className="type-icon-containerr">
          {getIcon(sheet?.privacy_type)}
        </div>
      </div>
      <span className="sheet-name mx-2">{sheet.name}</span>
    </Nav.Link>
        {/* Sheet menu with conditional actions */}
        <i className="bi bi-three-dots-vertical sheet-menu-icon" onClick={() => handleSheetMenuClick(sheet?.id)}></i>
        {openMenuSheetId === sheet?.id && isSheetMenuVisible && (
  <div ref={menuRef} className="sheet-menu">
    
    {/* Edit Option */}
    {!(sheet?.is_admin) ? (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-edit-${sheet?.id}`}>You need admin access and edit permissions</Tooltip>}
      >
        <span className="d-inline-block">
          <p className="menu-item" style={{ color: 'gray', cursor: 'not-allowed' }}>
            <BiPencil className="me-2" /> Edit
          </p>
        </span>
      </OverlayTrigger>
    ) : (
      <p className="menu-item" onClick={() => handleEditClick(sheet)}>
        <BiPencil className="me-2" /> Edit
      </p>
    )}

    {/* Delete Option */}
    {!(sheet?.is_admin) ? (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-delete-${sheet?.id}`}>You need admin access and delete permissions</Tooltip>}
      >
        <span className="d-inline-block">
          <p className="menu-item" style={{ color: 'gray', cursor: 'not-allowed' }}>
            <BiTrash className="me-2" /> Delete
          </p>
        </span>
      </OverlayTrigger>
    ) : (
      <p className="menu-item" onClick={() => handleDeleteSheet(sheet?.id)}>
        <BiTrash className="me-2" /> Delete
      </p>
    )}

    {/* Share Option */}
    {!(sheet?.is_admin) ? (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-share-${sheet?.id}`}>You need admin access to share</Tooltip>}
      >
        <span className="d-inline-block">
          <p className="menu-item" style={{ color: 'gray', cursor: 'not-allowed' }}>
            <BiShareAlt className="me-2" /> Share
          </p>
        </span>
      </OverlayTrigger>
    ) : (
      <p className="menu-item" onClick={() => handleShareSheetClick(sheet?.id)}>
        <BiShareAlt className="me-2" /> Share
      </p>
    )}
  </div>
)}


 {/* {openMenuSheetId === sheet?.id && isSheetMenuVisible && (
                <div ref={menuRef} className="sheet-menu">
                  <p className="menu-item" onClick={() => handleEditClick(sheet)}>
                    <BiPencil className="me-2" /> Edit
                  </p>
                  <p className="menu-item" onClick={() => handleDeleteSheet(sheet?.id)}>
                    <BiTrash className="me-2" /> Delete
                  </p>
                  <p
          className="menu-item"
          onClick={ () =>handleShareSheetClick(sheet?.id)}
        >
          <BiShareAlt className="me-2" /> Share
        </p>
                </div>
              )} */}
{showShareModal && (
      <ShareSheetModal
        showModal={showShareModal}
        handleClose={() => setShowShareModal(false)}
        sheetId={sheetIdToShare}
        sharedById={user?.userid} 
        users={users} 
      />
    )}
      </div>
    ))
}

    </div>
  ))}
</Nav>

      <div className='sidebar-bottom'>
      {user && (
  <h6
    className={` ${getUserRoleClass(user.role)}`}
  >
    {user.role}
  </h6>
)}  
<hr style={{color:"black", marginTop:"-1px"}}/>

<div className="sidebar-footer d-flex align-items-center justify-content-around" >
        
        <img
          src={`${process.env.REACT_APP_TOS_PATH}/assets/svg/new/hotwash.png`}
          height="30"
          width={30}
          onClick={toggleMenu} 
          // className="d-inline-block align-top rounded-circle"
          style={{ cursor: 'pointer', objectFit: 'cover' }}
          alt="Hotwash Logo"
        />
        <BiInfoCircle size={30}  style={{cursor:'pointer'}}/>
        {isDarkMode ? (
        <BsSun size={30} onClick={toggleTheme}  style={{cursor:'pointer'}}/>
      ) : (
        <BsMoon size={30} onClick={toggleTheme}  style={{cursor:'pointer'}} />
      )}
      {console.log("menuVisible",menuVisible)}
      {menuVisible && (
        <div className="custom-menu"  ref={menuRef}>
          <div className="custom-menu-item" onClick={() => handleNavigation(`${process.env.REACT_APP_TOS_PATH}/includes/Pages/Home.php`)}
            style={{ cursor: 'pointer' }} >
            <img
              src="/GS logo.svg"
              alt="Grade Sheet"
              height={45}
              width={45}
            />
            <span>Grade Sheet</span>
          </div>
          <div className="custom-menu-item" onClick={() => handleNavigation(`${process.env.REACT_APP_TOS_PATH}/Library/newLabUI.php`)}
            style={{ cursor: 'pointer' }}>
            <img
              src="/L logo.svg"
              alt="Library"
              height={45}
              width={45}
            />
            <span>Library</span>
          </div>
          <div className="custom-menu-item" onClick={() => handleNavigation(`${process.env.REACT_APP_API_ROOT}/sch/schedule-home/`)}
            style={{ cursor: 'pointer' }}>
            <img
              src="/S logo.svg"
              alt="Scheduling"
              height={45}
              width={45}
            />
            <span>Scheduling</span>
          </div>
          <div className="custom-menu-item" onClick={() => handleNavigation('http://localhost:3000/')}
            style={{ cursor: 'pointer' }}>
            <img
              src="/hotwash.png"

              alt="Hotwash"
              height={45}
              width={45}
            />
            <span>Hotwash</span>
          </div>
          <div className="custom-menu-item"   onClick={() => handleNavigation(`${process.env.REACT_APP_TOS_PATH}/Test/dashboard/dashboard.php`)}
            style={{ cursor: 'pointer' }} >
            <img
              src="/test.png"

              alt="Test"
              height={45}
              width={45}
            />
            <span>Test</span>
          </div>
          <div className="custom-menu-item"  onClick={() => handleNavigation(`${process.env.REACT_APP_TOS_PATH}/includes/Pages/homeChecklist.php`)}
            style={{ cursor: 'pointer' }}>
            <img
              // src="${process.env.TOS_ROOT_ATH}/assets/svg/new/checklist8.png"
              src="/checklist8.png"

              alt="Checklist"
              height={45}
              width={45}
            />
            <span>Checklist</span>
          </div>
          <div className="custom-menu-item" onClick={() => handleNavigation(`${process.env.REACT_APP_TOS_PATH}/includes/Pages/notesadd.php`)}
            style={{ cursor: 'pointer' }}>
            <img
              src="/lettern.png"
              alt="Notes"
              height={45}
              width={45}
            />
            <span>Notes</span>
          </div>
        </div>
      )}
      
      </div> 
      </div>
      <ImportSheetModal show={modalShow} handleClose={handleCloseImportModal} openMenuFrameId={openMenuFrameId} userId={user?.userid}/>
      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Create Blueprint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <label htmlFor="ctp-symbol">Select CTP:</label>
              <select
                id="ctp-symbol"
                value={selectedSymbol}
                onChange={handleDropdownChange}
                style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginTop: '5px' }}
              >
                <option value="">Select CTP</option>
                {ctps.map(c => (
                  <option key={c.ctpid} value={c.course}>
                    {c.course}
                  </option>
                ))}
              </select>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalVisible(false)}>Close</Button>
          <Button variant="primary" onClick={handleSubmit}>          {loadings ? <Spinner as="span" animation="border" size="sm" /> : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
      <Modal.Header>
        <Modal.Title>
          <img
            src="/avtar.png"  // Replace with a dynamic path if necessary
            alt="avatar"
            style={{ height: '50px', width: '50px', borderRadius: '50%' }}
          />
          <span style={{ marginLeft: '10px', fontSize: 'xx-large', color: '#00C9A7' }}>
            {isDepartmentView ? 'Alkarama' : 'Division'}
          </span>
        </Modal.Title>
        <input 
          className="form-control" 
          type="text" 
          id="depsearch" 
          placeholder={isDepartmentView ? 'Search for Department...' : 'Search for Division...'}
          title="Type in a name"
          value={searchTerm}
          onChange={handleSearch}  // Added search handler
          style={{ width: '300px', marginLeft: 'auto', marginRight: '20px' }}  // Align search bar to the top-right
        />
        {/* <Button variant="link" onClick={handleClearSearch}>
          <i className="bi bi-x-lg text-secondary"></i>  
        </Button> */}
        <Button variant="link" onClick={handleCloseModal} style={{ marginLeft: '10px' }}>
          <i className="bi bi-x-lg text-secondary"></i>  {/* Bootstrap Icons close icon */}
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {isDepartmentView ? (
            <>
              <Row>
                {filteredDepartments.map(department => (
                  <Col xs={12} md={4} key={department.id} className="mb-2">
                    <Button
                      className="w-100 d-flex align-items-center p-2 border text-white rounded"
                      onClick={() => {
                        handleDivisionFetch(department);
                        setDepartmentName(department.department_name); // Update departmentName state
                      }}
                      style={{ background: "#71869D", fontSize: "x-large" }}
                    >
                       <img
                                  src="/avtar.png"  // Replace with a dynamic path if necessary
                                  alt="avatar"
                                  style={{ height: '50px', width: '50px', borderRadius: '50%' }}
                               
                        // src={/${department.department_avatar}}  // Replace with a dynamic path for department avatar
                        // alt={${department.department_name} Avatar}
                        // style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '10px' }}  // Avatar style
                      />
                      <span className='mx-1'>{department.department_name}</span>
                    </Button>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <>
              {filteredDivisions.length === 0 ? (
                <Row>
                  <Col xs={12} className="text-center">
                    <div className="alert alert-warning">
                      <p className="mb-2">No divisions exist for this department. Please select another department.</p>
                      <Button variant="link" onClick={handleGoBack}>Go Back</Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                // <Row>
                //   {filteredDivisions.map(division => (
                //     <Col xs={12} md={4} key={division.id} className="mb-2">
                //       <Button
                //         variant="secondary"  // Changed to secondary color
                //         className="w-100"
                //         onClick={() => handleDivisionSelect(division)}
                //       >
                //         {division.department_name}
                //       </Button>
                //     </Col>
                //   ))}
                // </Row>
                <Row>
      <div className="button-container">
        {filteredDivisions.map(division => (
          <Button
            key={division.id}
            variant="secondary"
            className="dynamic-button w-auto py-2 "

            style={{ width: `${getButtonWidth(division.department_name)}px` ,fontSize: "large"}}
            onClick={() => handleDivisionSelect(division)}
          >
            {division.department_name}
          </Button>
        ))}
      </div>
    </Row>
              )}
            </>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
      <Modal show={isSharePopupVisible} onHide={() => setIsSharePopupVisible(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Share with Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="">
          <Form.Control
            placeholder="Search users"
            aria-label="Search users"
            aria-describedby="search-icon"
            value={searchQuery}
            onChange={handleSearchChange}
            className=""
          />
          <InputGroup.Text id="search-icon">
            <BsSearch />
          </InputGroup.Text>
        </InputGroup>
        <ul className="list-unstyled user-list ">
          {users
            .filter((user) =>
              user.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((user) => (
              <li
                key={user.id}
                onClick={() => handleUserSelect(user)}
                className={`user-item d-flex align-items-center mb-2 ${
                  selectedUsers.some((u) => u.id === user.id) ? 'selected' : ''
                }`}
              >
                <div  style={{position:'relative'}}> <img
                  src={user?.avatar||`/upload/${user?.file_name}`}
                  alt='Avatar'
                  className="rounded-circle me-2"
                  width="30"
                  height="30"
                /></div>
               
                <div>
                  <div>{user.name}</div>
                  <div className="text-muted" style={{ fontSize: '0.85rem' }}>
                    {user.email}
                  </div>
                </div>
              </li>
            ))}
        </ul>
        <hr />
        <h5>Selected Users:</h5>
        <div className="selected-users d-flex flex-wrap mb-3">
          {selectedUsers.map((user) => (
            <div
              key={user.id}
              className="selected-user d-flex align-items-center me-2 mb-2"
            >
              <img
                                src={user?.avatar||`/upload/${user?.file_name}`}

                // src={user.avatar || 'default-avatar.png'}
                alt={user.name}
                className="rounded-circle me-1"
                width="20"
                height="20"
              />
              <span>{user.name}</span>
              <BsX className="ml-1 cursor-pointer remove-icon" onClick={() => handleRemoveUser(user.id)} />
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsSharePopupVisible(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleShare}>
          Share
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal show={showEditModal} onHide={handleEditModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Work Space</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleEditSubmit}>
          <Modal.Body>
            <Form.Group controlId="frameName">
              <Form.Label>
                Work Space Name <span className="mandatory">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter frame name"
                value={frameName}
                onChange={(e) => setFrameName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="privacyType" className="mt-4">
              <Form.Label>
                Privacy Type <span className="mandatory">*</span>
              </Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Private"
                  name="privacyType"
                  type="radio"
                  id="private"
                  value="private"
                  checked={privacyType === 'private'}
                  onChange={(e) => setPrivacyType(e.target.value)}
                  required
                />
                <Form.Check
                  inline
                  label="Public"
                  name="privacyType"
                  type="radio"
                  id="public"
                  value="public"
                  checked={privacyType === 'public'}
                  onChange={(e) => setPrivacyType(e.target.value)}
                  required
                />
                <Form.Check
                  inline
                  label="Sharable"
                  name="privacyType"
                  type="radio"
                  id="sharable"
                  value="sharable"
                  checked={privacyType === 'sharable'}
                  onChange={(e) => setPrivacyType(e.target.value)}
                  required
                />
              </div>
            </Form.Group>

            <Form.Group controlId="description" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="tags" className="mt-3">
              <Form.Label>Tags</Form.Label>
              <Select
                isMulti
                options={tags}
                value={selectedTags}
                onChange={(selected) => setSelectedTags(selected)}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Form.Group>
            <Form.Group controlId="iconUpload" className="mt-3">
              <Form.Label>Work Space Icon</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="file"
                  accept="image/*"
                  className="me-3"
                  onChange={(e) => setIconFile(e.target.files[0])}
                />
                {/* <Button variant="secondary">Pick an Icon</Button> */}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditModalClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ConfirmationPopup
        isOpen={showConfirmation}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        isLoading={false} // You can set this to true when deleting
      />
      <ConfirmationPopup
        isOpen={showSheetConfirmation}
        onClose={handleCancelSheetDelete}
        onConfirm={handleConfirmSheetDelete}
        isLoading={false} // You can set this to true when deleting
      />
    <ConfirmationPopup
        isOpen={showConfirmation}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        isLoading={false} // You can set this to true when deleting
      />
    <Modal show={isSheetPopupVisible} onHide={handleCloseModalSheet} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Sheet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  <Form>
    <Form.Group controlId="sheetName">
      <Form.Label>Sheet Name</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter sheet name"
        value={sheetName}
         maxLength="50"
        onChange={(e) => setSheetName(e.target.value)}
      />
    </Form.Group>

    <Form.Group controlId="privacyType" className="mb-3">
      <Form.Label>Privacy Type</Form.Label>
      <div className="d-flex">
        <Form.Check
          type="radio"
          label="Public"
          name="privacyType"
          value="public"
          checked={privacyType === 'public'}
          onChange={() => setPrivacyType('public')}
          className="me-3"
        />
        <Form.Check
          type="radio"
          label="Private"
          name="privacyType"
          value="private"
          checked={privacyType === 'private'}
          onChange={() => setPrivacyType('private')}
          className="me-3"
        />
        <Form.Check
          type="radio"
          label="Sharable"
          name="privacyType"
          value="sharable"
          checked={privacyType === 'sharable'}
          onChange={() => setPrivacyType('sharable')}
        />
      </div>
    </Form.Group>

    <Form.Group controlId="managementType">
  <Form.Label>Select what you're Managing in this Sheet</Form.Label>
  <div className="d-flex flex-wrap">
    {/* First Row of Radio Buttons */}
    <div className="d-flex mb-3">
      <Form.Check
        type="radio"
        label="Budgets"
        name="managementType"
        value="budgets"
        onChange={() => setManagementType('budgets')}

        className="me-3"
      />
      <Form.Check
        type="radio"
        label="Employees"
        name="managementType"
        value="employees"
        onChange={() => setManagementType('employees')}

        className="me-3"
      />
      <Form.Check
        type="radio"
        label="Projects"
        name="managementType"
        onChange={() => setManagementType('projects')}

        value="projects"
        className="me-3"
      />
    </div>
    {/* Second Row of Radio Buttons */}
    <div className="d-flex mb-3">
      <Form.Check
        type="radio"
        label="Clients"
        name="managementType"
        value="clients"
        onChange={() => setManagementType('clients')}

        className="me-3"
      />
      <Form.Check
        type="radio"
        label="Tasks"
        name="managementType"
        value="tasks"
        onChange={() => setManagementType('tasks')}

        className="me-3"
      />
      {/* Custom Input Radio Button */}
      <div className="custom-radio-option d-flex align-items-center">
      <Form.Check
                  type="radio"
                  label={
                    <input
                      type="text"
                      className="form-control custom-radio-input"
                      placeholder="Custom"
                      value={customManagementType}
                      onChange={(e) => setCustomManagementType(e.target.value)}
                      onClick={() => setManagementType('custom')}
                    />
                  }
                  name="managementType"
                  value="custom"
                  checked={managementType === 'custom'}
                  className="me-3"
                />
      </div>
    </div>
  </div>
</Form.Group>

  </Form>
</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsSheetPopupVisible(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateSheetSubmit}>
          {loadings ? <Spinner as="span" animation="border" size="sm" /> : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isUpdateSheetPopupVisible} onHide={handleCloseModalUpdateSheet} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Sheet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="sheetName">
              <Form.Label>Sheet Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter sheet name"
                value={sheetName}
                onChange={(e) => setSheetName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="privacyType" className="mb-3">
              <Form.Label>Privacy Type</Form.Label>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  label="Public"
                  name="privacyType"
                  value="public"
                  checked={privacyType === 'public'}
                  onChange={() => setPrivacyType('public')}
                  className="me-3"
                />
                <Form.Check
                  type="radio"
                  label="Private"
                  name="privacyType"
                  value="private"
                  checked={privacyType === 'private'}
                  onChange={() => setPrivacyType('private')}
                  className="me-3"
                />
                <Form.Check
                  type="radio"
                  label="Sharable"
                  name="privacyType"
                  value="sharable"
                  checked={privacyType === 'sharable'}
                  onChange={() => setPrivacyType('sharable')}
                />
              </div>
            </Form.Group>

            {/* <Form.Group controlId="managementType">
              <Form.Label>Select what you're Managing in this Sheet</Form.Label>
              <div className="d-flex flex-wrap">
                <div className="d-flex mb-3">
                  <Form.Check
                    type="radio"
                    label="Budgets"
                    name="managementType"
                    value="budgets"
                    checked={managementType === 'budgets'}
                    onChange={() => setManagementType('budgets')}
                    className="me-3"
                  />
                  <Form.Check
                    type="radio"
                    label="Employees"
                    name="managementType"
                    value="employees"
                    checked={managementType === 'employees'}
                    onChange={() => setManagementType('employees')}
                    className="me-3"
                  />
                  <Form.Check
                    type="radio"
                    label="Projects"
                    name="managementType"
                    value="projects"
                    checked={managementType === 'projects'}
                    onChange={() => setManagementType('projects')}
                    className="me-3"
                  />
                </div>
                <div className="d-flex mb-3">
                  <Form.Check
                    type="radio"
                    label="Clients"
                    name="managementType"
                    value="clients"
                    checked={managementType === 'clients'}
                    onChange={() => setManagementType('clients')}
                    className="me-3"
                  />
                  <Form.Check
                    type="radio"
                    label="Tasks"
                    name="managementType"
                    value="tasks"
                    checked={managementType === 'tasks'}
                    onChange={() => setManagementType('tasks')}
                    className="me-3"
                  />
                  <div className="custom-radio-option d-flex align-items-center">
                    <Form.Check
                      type="radio"
                      label={<input type="text" className="form-control custom-radio-input" placeholder="Custom" />}
                      name="managementType"
                      value="custom"
                      checked={managementType === 'custom'}
                      onChange={() => setManagementType('custom')}
                      className="me-3"
                    />
                  </div>
                </div>
              </div>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsUpdateSheetPopupVisible(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateSheetSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
