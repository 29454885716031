import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// export const fetchFrames = createAsyncThunk('frames/fetchFrames', async () => {
//   const response = await axios.get('${process.env.REACT_APP_API_ROOT}/hotwash/workspaces/');
//   return response.data;
// });
    /*eslint-disable*/
export const fetchFrames = createAsyncThunk('frames/fetchFrames', async (params) => {
  const { DeptId, DivId ,globalUserId} = params;
  const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/filter-workspaces/${DeptId}/${globalUserId}`);
  return response.data;
});
    /*eslint-enable*/
export const addFrame = createAsyncThunk('frames/addFrame', async (formData) => {
  const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/add-workspace/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
});

export const updateFrame = createAsyncThunk('frames/updateFrame', async (formData) => {
  const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/update-workspace/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
});

export const deleteFrame = createAsyncThunk('frames/deleteFrame', async (id) => {
  await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/delete-workspace/${id}/`);
  return id;
});
export const deleteSheet = createAsyncThunk('frames/deletesheet', async (id) => {
  await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/delete-sheet/${id}/`);
  return id;
});
export const deleteGroup = createAsyncThunk('frames/deletegroup', async (id) => {
  await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/delete-group/${id}/`);
  return id;
});
export const deleteRow = createAsyncThunk('frames/deleterow', async (id) => {
  await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/delete-row/${id}/`);
  return id;
});
export const deleteSubRow = createAsyncThunk('frames/deletesubrow', async (id) => {
  await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/delete-sub-row/${id}/`);
  return id;
});
export const deleteColumn = createAsyncThunk('frames/deletecolumn', async (id) => {
  await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/delete-column/${id}/`);
  return id;
});

