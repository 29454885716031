import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { BiDotsHorizontal, BiTrash } from 'react-icons/bi';
import { FaEdit } from 'react-icons/fa';

const DraggableSubColumn = ({ subHeader, subColumnWidth, onResizeSubColumn, fetchData }) => {
  const subColumnRef = useRef(null);
  const columnMenuRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newSubColumnName, setNewSubColumnName] = useState(subHeader?.name);
  const [isColumnMenuVisible, setIsColumnMenuVisible] = useState(false);
  const [openMenuSubColumnId, setOpenMenuSubColumnId] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnMenuRef.current && 
        !columnMenuRef.current.contains(event.target) && 
        !subColumnRef.current.contains(event.target)
      ) {
        setIsColumnMenuVisible(false);
        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isEditing) {
      const input = document.querySelector('.sub-header-cell input');
      if (input) {
        input.focus();
      }
    }
  }, [isEditing]);

  const handleResizeMouseDownSub = (e) => {
    const startX = e.clientX;
    const startWidth = subColumnRef.current.offsetWidth;

    const doDrag = (e) => {
      const newWidth = startWidth + (e.clientX - startX);
      onResizeSubColumn(subHeader.id, newWidth);
    };

    const stopDrag = async () => {
      document.removeEventListener('mousemove', doDrag);
      document.removeEventListener('mouseup', stopDrag);
      
      try {
        await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/update-sub-column/`, {
          id: subHeader.id,
          column_width: subColumnRef.current.offsetWidth,
        });
      } catch (error) {
        console.error('Error updating sub-column width:', error);
      }
    };

    document.addEventListener('mousemove', doDrag);
    document.addEventListener('mouseup', stopDrag);
  };

  const handleRenameSubColumn = async (e) => {
    if (e.key === 'Enter' || e.type === 'blur') {
      try {
        await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/update-sub-column/`, {
          id: subHeader.id,
          name: newSubColumnName,
        });
        setIsEditing(false);
        setIsColumnMenuVisible(false);
        fetchData();
      } catch (error) {
        console.error('Error renaming sub-column:', error);
      }
    }
  };

  const handleAddSubColumnMenuClick = (event) => {
    event.stopPropagation();
    setOpenMenuSubColumnId(subHeader?.id);
    setIsColumnMenuVisible(!isColumnMenuVisible);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDeleteSubColumn = async (subColumnId) => {
    try {
      await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/delete-sub-column/${subColumnId}/`);
      setIsColumnMenuVisible(false);
      fetchData();
    } catch (error) {
      console.error('Error deleting sub-column:', error);
    }
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const renderSubColumnName = () => {
    if (isEditing) {
      return (
        <input
          type="text"
          value={newSubColumnName}
          onChange={(e) => setNewSubColumnName(e.target.value)}
          onKeyPress={handleRenameSubColumn}
          onBlur={handleRenameSubColumn}
          autoFocus
        />
      );
    }
    return (
      <>
        {subHeader.name}
        <BiDotsHorizontal
          className="three-dots"
          style={{ cursor: 'pointer', marginLeft: '10px' }}
          onClick={handleAddSubColumnMenuClick}
        />
      </>
    );
  };

  return (
    <th
      ref={subColumnRef}
      style={{ width: `${subColumnWidth}px`, position: 'relative' }}
      className="sub-header-cell"
      onDoubleClick={handleDoubleClick}
    >
      {renderSubColumnName()}
      <div
        className="resizer"
        onMouseDown={handleResizeMouseDownSub}
      />
      {openMenuSubColumnId === subHeader?.id && isColumnMenuVisible && (
        <div ref={columnMenuRef} className="add-column-groups frame-name">
          <p className="menu-item" onClick={handleEdit}>
            <FaEdit className="me-2" /> Rename
          </p>
          <p className="menu-item" onClick={() => handleDeleteSubColumn(subHeader.id)}>
            <BiTrash className="me-2" /> Delete
          </p>
        </div>
      )}
    </th>
  );
};

export default DraggableSubColumn;
