import React, { useRef, useEffect } from 'react';
import axios from 'axios';
import { FaPlus } from 'react-icons/fa';
import { BiDotsHorizontal } from 'react-icons/bi';

const FileUpload = ({ ID, docs, fetchData, cellId, onDotsClick }) => {
  const cellRef = useRef(null);
  
  const handleFileChange = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('cell_id', ID);
      formData.append('doc_file', file);
      formData.append('doc_type', 'main');

      try {
        await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/add-doc-file/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        fetchData(); // Refresh data after upload
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file) {
          handleFileChange(file);
        }
      }
    }
  };
    /*eslint-disable*/
  useEffect(() => {
    const handleClick = (event) => {
      if (cellRef.current && cellRef.current.contains(event.target)) {
        // Attach the paste event only for this cell when it is clicked
        document.addEventListener('paste', handlePaste);
      } else {
        // Remove paste listener when clicking outside the cell
        document.removeEventListener('paste', handlePaste);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('paste', handlePaste); // Cleanup on unmount
    };
  }, []);
    /*eslint-enable*/
  const getFileSrcAndClass = (docFile) => {
    const extension = docFile.split('.').pop().toLowerCase();
    const imageExtensions = ['png', 'jpg', 'jpeg', 'svg', 'webp'];
    const fileext = ['pdf'];
    if (imageExtensions.includes(extension)) {
      return {
        src: `${process.env.REACT_APP_API_ROOT}/${docFile}`,
        className: 'image-file',
      };
    }
    if (fileext.includes(extension)) {
      return {
        src: 'https://cdn.monday.com/images/file-types/pdf_v2.svg',
        className: 'image-file',
      };
    }
    return {
      src: 'https://cdn.monday.com/images/file-types/unknown_v3.svg',
      className: 'doc-file',
    };
  };

  const handleDotsClick = (event) => {
    event.stopPropagation();
    if (onDotsClick) {
      onDotsClick(event, docs);
    }
  };

  return (
    <div
      ref={cellRef}
      className={`cell-container ${docs && docs.length > 0 ? 'docs-present' : 'no-docs'}`}
      data-cell-id={cellId}
    >
      {docs && docs.length > 0 && (
        <div className="dots-container">
          <BiDotsHorizontal className="dots-icon" style={{ cursor: 'pointer' }} onClick={handleDotsClick} />
        </div>
      )}
      <div className="docs-container">
        <ul className="doc-list">
          {docs?.map(doc => {
            const { src, className } = getFileSrcAndClass(doc.doc_file);
            return (
              <a href={`${process.env.REACT_APP_API_ROOT}/${doc.doc_file}`} key={doc.id} target="_blank" rel="noopener noreferrer" className="doc-link">
                <img
                  src={src}
                  alt="Document"
                  className={`doc-image ${className}`}
                />
              </a>
            );
          })}
        </ul>
        <label className="custom-file-upload">
          <FaPlus className="plus-icon" />
          <input
            type="file"
            onChange={(e) => handleFileChange(e.target.files[0])}
            className="form-control no-border"
          />
        </label>
      </div>
    </div>
  );
};

export default FileUpload;
