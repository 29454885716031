import { configureStore } from '@reduxjs/toolkit';
import workspaceReducer from '../features/workspaces/workspaceReducer';
import departmentReducer from '../features/Department/departmentReducer';
import divisionReducer from '../features/Division/divisionReducer';

export const store = configureStore({
  reducer: {
    frames: workspaceReducer,
    department:departmentReducer,
    division:divisionReducer
  }
});
