import React, { useEffect, useRef, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import DatePicker from 'react-datepicker';
import 'react-date-range/dist/styles.css'; // main css file for DateRangePicker
import 'react-date-range/dist/theme/default.css'; // theme css file for DateRangePicker
import 'react-datepicker/dist/react-datepicker.css'; // CSS for react-datepicker
import TaskFilesSection from './TaskFileSelection';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

// Utility to get file type icon or display the image
const getFileSrcAndClass = (docFile) => {
  const extension = docFile.split('.').pop().toLowerCase();
  const imageExtensions = ['png', 'jpg', 'jpeg', 'svg', 'webp'];
  const pdfExtensions = ['pdf'];

  if (imageExtensions.includes(extension)) {
    return {
      src: `${process.env.REACT_APP_API_ROOT}/${docFile}`,
      className: 'image-file',
    };
  }
  if (pdfExtensions.includes(extension)) {
    return {
      src: 'https://cdn.monday.com/images/file-types/pdf_v2.svg',
      className: 'pdf-file',
    };
  }
  return {
    src: 'https://cdn.monday.com/images/file-types/unknown_v3.svg',
    className: 'unknown-file',
  };
};

const TaskDetailsOffcanvas = ({ showDrawer, handleShow, taskDetails,setTaskDetails,selectedCard,fetchData,setOffcanvasState,offcanvasState,globalUserId}) => {
  const staticPath = `${process.env.REACT_APP_TOS_PATH}/includes/Pages/upload/`;
  const [dropdownPosition, setDropdownPosition] = useState(null);
  const [activeCell, setActiveCell] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showTimelinePicker, setShowTimelinePicker] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentCell, setCurrentCell] = useState(null);
  const [userData, setUserData] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [multiDropdown, setMultiDropdown] = useState({
    visible: false,
    position: { top: 0, left: 0 },
    activeCell: null,  // Active cell data for dropdown
    dropdownOptions: [],  // Options fetched from API
  });
  
  const [userMenu, setUserMenu] = useState({
    visible: false,
    position: { top: 0, left: 0 },
    activeCell: null,
  });
    // const userMenuRef = useRef(null);
  const dropdownRef = useRef(null);
  const [selectedTimelineRange, setSelectedTimelineRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const DeptID = localStorage.getItem('HDept');

  // Timeline picker change handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowTimelinePicker(false);
        setShowDatePicker(false);
        setActiveCell(null); // Deselect the active cell
        setUserMenu({
            visible: false,
            position: { top: 0, left: 0 },
            activeCell: null,
          })
        setMultiDropdown({
            visible: false,
            position: { top: 0, left: 0 },
            activeCell: null,  // Active cell data for dropdown
            dropdownOptions: [],  // Options fetched from API
          });
      }
    };

    // Add event listener when dropdown is visible
    if (showTimelinePicker || showDatePicker || activeCell || multiDropdown ||userMenu ) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTimelinePicker, showDatePicker,activeCell,userMenu,multiDropdown]);
console.log("multiDropdown",multiDropdown)
const handleInputChange = (e, item) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = async (item) => {
    // Call the API to update the cell data
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${item.id}/`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ cell_data: inputValue }),
      });

      if (!response.ok) {
        throw new Error('Failed to update cell data');
      }

      const data = await response.json();
      await updateTaskDetails(selectedCard);
      setOffcanvasState(!offcanvasState)
      fetchData()
      console.log('Cell data updated successfully:', data);

      // Reset editing state
      setEditingCell(null);
    } catch (error) {
      console.error('Error updating cell data:', error);
    }
  };
  const handleKeyDown = (e, item) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default action (form submission)
      handleInputBlur(item);
    }
  };

  // Handle click for timeline cell
  const handleTimelineClick = (item,event) => {
    setCurrentCell(item);
    setShowTimelinePicker(true);
    const rect = event.currentTarget.getBoundingClientRect();
    const pickerHeight = 300;
    const windowHeight = window.innerHeight;
    let top = rect.bottom + window.scrollY;
    if (windowHeight - rect.bottom < pickerHeight) {
      top = rect.top - pickerHeight + window.scrollY;
    }
    setDropdownPosition({
        top,
        left: rect.left + window.scrollX,
        bottomPosition: false,
      });
  };

  // Handle click for date cell
  const handleDateClick = (item, event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const pickerHeight = 300;
    const windowHeight = window.innerHeight;

    let top = rect.bottom + window.scrollY;
    if (windowHeight - rect.bottom < pickerHeight) {
      top = rect.top - pickerHeight + window.scrollY;
    }

    setCurrentCell(item);
    setShowDatePicker(true);

    setDropdownPosition({
      top,
      left: rect.left + window.scrollX,
      bottomPosition: false,
    });
  };
  const handleMultiDropdownClick = async (e, item) => {
    const position = {
      top: e.target.getBoundingClientRect().top,
      left: e.target.getBoundingClientRect().left,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/multi-dropdowns/main/${item?.column?.id}/`);
      const data = await response.json();
  
      setMultiDropdown({
        visible: true,
        position: position,
        activeCell: item,  // Store the current cell data
        dropdownOptions: data,  // Store fetched dropdown options
      });
    } catch (error) {
      console.error('Error fetching dropdown options:', error);
    }
  };
  
  const handleDropdownClick = async (event, item) => {
    // const rect = event.currentTarget.getBoundingClientRect();
    // const dropdownHeight = 200; 
    // const windowHeight = window.innerHeight;
  
    // let top = rect.bottom + window.scrollY;
    // if (windowHeight - rect.bottom < dropdownHeight) {
    //   top = rect.top - dropdownHeight + window.scrollY;
    // }
    const rect = event.target.getBoundingClientRect();
    // const iconPosition = event.currentTarget.getBoundingClientRect();

    setDropdownPosition({ top: rect.bottom + window.scrollY,
        left: rect.left});
  
    setActiveCell(item);
  
    // Fetch dropdown options
    if (item?.column_type === 'dropdown') {
      try {
        const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/dropdown-status/main/${item.column.id}/`;
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch dropdown options');
        }
        const options = await response.json();
        setActiveCell((prev) => ({
          ...prev,
          dropdown_options: options,
        }));
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    }
  };
  

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setShowDatePicker(false);
  
    // Convert date to Indian Standard Time (IST) and format to 'YYYY-MM-DD'
    const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
    const localDate = new Date(date.getTime() + istOffset); // Adjust to IST
    const formattedDate = localDate.toISOString().split('T')[0]; // Extract 'YYYY-MM-DD'
  
    try {
      const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${currentCell.id}/`;
      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cell_data: formattedDate }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update date');
      }
      const data = await response.json();
      await updateTaskDetails(selectedCard);
      setOffcanvasState(!offcanvasState);
      fetchData();
      console.log('Updated date:', data);
    } catch (error) {
      console.error('Error updating date:', error);
    }
  
    setDropdownPosition(null);
    setCurrentCell(null);
  };
  
  
  const updateTaskDetails = async (taskId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/kanban-task/${taskId}/`);
      if (!response.ok) {
        throw new Error('Failed to fetch updated task details');
      }
      const updatedTask = await response.json();
      setTaskDetails(updatedTask); // Update task details in the state
    } catch (error) {
      console.error("Error updating task details:", error);
    }
  };
  const handleStatusSelect = async (status) => {
    const rowData = {
      cell_data: {
        status_text: status.status_text,
        status_color: status.status_color,
        id: status.id,
      },
    };

    try {
      const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${activeCell.id}/`;
      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rowData),
      });

      if (!response.ok) {
        throw new Error('Failed to update cell data');
      }

      const data = await response.json();
      await updateTaskDetails(selectedCard);
      setOffcanvasState(!offcanvasState)

      fetchData()
      console.log('Updated cell:', data);
    } catch (error) {
      console.error('Error updating cell:', error);
    }

    setDropdownPosition(null);
    setActiveCell(null);
  };
  const handleTimelineChange = (ranges) => {
    const selectedRange = ranges.selection;
    setSelectedTimelineRange(selectedRange);
    setShowTimelinePicker(false);
  
    const currentYear = new Date().getFullYear();
  
    // Format options for date strings
    const optionsWithoutYear = { day: '2-digit', month: 'short' }; // Format without year
    const optionsWithYear = { day: '2-digit', month: 'short', year: 'numeric' }; // Format with year
  
    // Extract year from the selected range
    const startYear = selectedRange.startDate.getFullYear();
    const endYear = selectedRange.endDate.getFullYear();
  
    // Determine if the selected dates are in the current year
    const useShortFormat = (startYear === currentYear) && (endYear === currentYear);
  
    // Format dates accordingly
    const startDateFormatted = selectedRange.startDate.toLocaleDateString('en-GB', useShortFormat ? optionsWithoutYear : optionsWithYear);
    const endDateFormatted = selectedRange.endDate.toLocaleDateString('en-GB', useShortFormat ? optionsWithoutYear : optionsWithYear);
    
    const formattedTimeline = `${startDateFormatted} - ${endDateFormatted}`;
  
    // API call to update timeline
    const updateTimeline = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_ROOT}/hotwash/cell/${currentCell.id}/`;
        const response = await fetch(apiUrl, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ cell_data: formattedTimeline }), // Send formatted string
        });
  
        if (!response.ok) {
          throw new Error('Failed to update timeline data');
        }
  
        const data = await response.json();
        await updateTaskDetails(selectedCard);
        setOffcanvasState(!offcanvasState);
  
        fetchData();
        console.log('Updated timeline:', data);
      } catch (error) {
        console.error('Error updating timeline:', error);
      }
    };
  
    updateTimeline();
  };
  const handleUserClick = async (e, item) => {
    const position = {
      top: e.target.getBoundingClientRect().top,
      left: e.target.getBoundingClientRect().left,
    };
  
    setUserMenu({
      visible: true,
      position: position,
      activeCell: item,  // Store the current cell
    });
  
    // Fetch the user data when the user cell is clicked
    await fetchUserData();
  };
  
  const handleUserSelect = async (user) => {
    // Clone current cell data
    const cellValue = Array.isArray(userMenu?.activeCell?.cell_data)
    ? [...userMenu.activeCell.cell_data]
    : [];  
    console.log("Current cell data:", user, cellValue);
  
    // Check if the user already exists in cell data
    const userIndex = cellValue.findIndex(u => u.id === user.id);
  
    if (userIndex === -1) {
      // If user is not found, add them to the list
      cellValue.push({
        user: user.name,
        id: user.id,
        file_name: user.file_name || null,  // In case file_name is optional
      });
    } else {
      // If user is found, remove them from the list
      cellValue.splice(userIndex, 1);
    }
  
    // Prepare the payload for updating the cell data
    const updatedCellData = cellValue.map(u => ({
      user: u.user,
      id: u.id,
      file_name: u.file_name,
    }));
  
    // Define row data for the API call
    const rowData = { cell_data: updatedCellData };
  
    try {
      // API call to update the cell data
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${userMenu.activeCell.id}/`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(rowData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update cell data');
      }
  
      const data = await response.json();
  
      // Update task details or UI after the update
      await updateTaskDetails(selectedCard);
      setOffcanvasState(!offcanvasState);
      fetchData()

      setUserMenu({
        visible: false,
        position: { top: 0, left: 0 },
        activeCell: null,
      })
      console.log('Cell data updated successfully:', data);
  
      // If the user was added (not removed), send a notification
      if (userIndex === -1) {
        const notificationPayload = {
          cell: userMenu.activeCell.id,
          user: globalUserId,  // Replace with your global user ID
          to_user: user.id,
          notification_type: "general",
        };
  
        const notificationResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/add-assignee-notification/`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(notificationPayload),
        });
  
        if (!notificationResponse.ok) {
          throw new Error('Failed to send notification');
        }
      }
  
    } catch (error) {
      console.error('Error updating cell data:', error);
    }
  };
  
  
  // Fetching user data for the dropdown
  const fetchUserData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/sch/get_user_data/${DeptID}/`);
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  const handleOptionSelect = async (selectedOption) => {
    // let updatedCellData = [...multiDropdown.activeCell.cell_data];
    const updatedCellData = Array.isArray(multiDropdown?.activeCell?.cell_data)
    ? [...multiDropdown?.activeCell?.cell_data]
    : [];
    const optionIndex = updatedCellData.findIndex(option => option.id === selectedOption.id);
  
    if (optionIndex !== -1) {
      // Option already exists, remove it (deselect)
      updatedCellData.splice(optionIndex, 1);
    } else {
      // Option doesn't exist, add it (select)
      updatedCellData.push(selectedOption);
    }
  
    // Update the state
    setMultiDropdown((prev) => ({
      ...prev,
      activeCell: {
        ...prev.activeCell,
        cell_data: updatedCellData,
      },
    }));
  
    // Send updated cell data to API
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${multiDropdown.activeCell.id}/`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ cell_data: updatedCellData }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update cell data');
      }
  
      const data = await response.json();
      await updateTaskDetails(selectedCard);
      fetchData()
      setOffcanvasState(!offcanvasState);
      setMultiDropdown({
        visible: false,
        position: { top: 0, left: 0 },
        activeCell: null,  // Active cell data for dropdown
        dropdownOptions: [],  // Options fetched from API
      })
      console.log('Cell data updated successfully:', data);
    } catch (error) {
      console.error('Error updating cell data:', error);
    }
  };
  
  const fixedColumnData = taskDetails?.task?.find(item => item.column_type === 'fixed')?.cell_data;

  return (
    <Offcanvas show={showDrawer} onHide={handleShow} placement="end" className="notification-drawer">
          <Offcanvas.Header>
        <Offcanvas.Title style={{ color: "black" }}>Task Details</Offcanvas.Title>
        {/* Custom Close Button with Emoji */}
        <Button variant="link" onClick={handleShow} style={{ fontSize: '24px', color: 'black', textDecoration: 'none' }}>
          <span role="img" aria-label="close" style={{ color: 'black' }}>✖</span>
        </Button>
      </Offcanvas.Header>


      <Offcanvas.Body>
      {taskDetails && (
          <div className="task-details-ui">
            {fixedColumnData && (
              <div className="task-section">
                <h1>{fixedColumnData}</h1>
              </div>
            )}
                          </div>
)}
      
        {taskDetails ? (
          <div className="task-details-ui py-0">
         {taskDetails?.task?.map((item, index) => (
  item.column_type !== 'fixed' && item.column_type !== 'file' && (
    <div key={index} className="task-field my-4 mx-2">
      <label className="field-label">{item.column?.name}:</label>
      <div className="field-data">
        {item?.column_type === 'timeline' ? (
          <div className="timeline-cell" style={{ position: 'relative' }}>
            <span style={{ cursor: "pointer" }} onClick={(e) => handleTimelineClick(item, e)}>
              {item.cell_data && typeof item.cell_data === 'string' ? item.cell_data : 'Select Date Range'}
            </span>
          </div>
        ) : item?.column_type === 'date' ? (
          <div className="date-cell" style={{ position: 'relative' }}>
            <span style={{ cursor: "pointer" }} onClick={(e) => handleDateClick(item, e)}>
              {item.cell_data && typeof item.cell_data === 'string' ? item.cell_data : 'Select Date'}
            </span>
          </div>
        ) : item?.column_type === 'dropdown' ? (
          <div className="status" onClick={(e) => handleDropdownClick(e, item)} style={{ backgroundColor: item.cell_data?.status_color, position: 'relative', cursor: "pointer" }}>
            {item.cell_data?.status_text || 'No status'}
          </div>
        ) : item?.column_type === 'users' ? (
          Array.isArray(item.cell_data) && item.cell_data.length > 0 ? (
            <div className="task-users">
              {item.cell_data.map((user, idx) => (
                <div key={idx} className="user" onClick={(e) => handleUserClick(e, item)} style={{ cursor: "pointer" }}>
                  <img className="user-avatar" src={`${staticPath}/${user?.file_name}`} alt={user.user} />
                  <span className="user-name">{user.user}</span>
                </div>
              ))}
            </div>
          ) : (
            <p onClick={(e) => handleUserClick(e, item)}>No users data available</p>
          )
        ) : item?.column_type === "multiDropdown" ? (
          Array.isArray(item.cell_data) && item.cell_data.length > 0 ? (
            <div className="multi-dropdowns">
              {item.cell_data.map((option, idx) => (
                <div key={idx} className="dropdown-options" onClick={(e) => handleMultiDropdownClick(e, item)} style={{ cursor: "pointer" }}>
                  {option.status_text || 'No option text'}
                </div>
              ))}
            </div>
          ) : (
            <p onClick={(e) => handleMultiDropdownClick(e, item)}>No data available</p>
          )
        ) : item?.column_type === "file" ? (
          item?.docs?.length > 0 ? (
            <div className="task-files">
              {item.docs.map((file, idx) => {
                const { src, className } = getFileSrcAndClass(file.doc_file);
                return (
                  <a
                    href={`${process.env.REACT_APP_API_ROOT}/${file.doc_file}`}
                    key={idx}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="file-link"
                  >
                    <img
                      src={src}
                      alt="File"
                      className={`file-img ${className}`}
                    />
                  </a>
                );
              })}
            </div>
          ) : (
            <p>No file attached</p>
          )
        ) : item?.column_type === 'text' || item?.column_type === 'integer' ? (
          editingCell === item.id ? (
            <input
              type="text"
              value={inputValue}
              onChange={(e) => handleInputChange(e, item)}
              onBlur={() => handleInputBlur(item)}
              onKeyDown={(e) => handleKeyDown(e, item)}
              autoFocus
              className="number-input"
            />
          ) : (
            <div
              className="number-cell"
              onClick={() => {
                setInputValue(typeof item.cell_data == 'object' && item.cell_data !== null ? JSON.stringify(item.cell_data) : item.cell_data || 'No data');
                setEditingCell(item.id);
              }}
            >
              {typeof item.cell_data === 'object' && item.cell_data !== null ? JSON.stringify(item.cell_data) : item.cell_data || 'No data'}
            </div>
          )
        ) : (
          <p>{typeof item.cell_data === 'object' && item.cell_data !== null ? JSON.stringify(item.cell_data) : item.cell_data || 'No data'}</p>
        )}
      </div>
    </div>
  )
))}

 {/* <div className="task-section task-files-section mx-2">
              <h5 className="section-title">Files:</h5>
              {taskDetails?.task
                .filter(item => item.column_type === "file" && item.docs.length > 0)
                ?.map((fileItem, idx) => (
                  <div key={idx} className="task-files">
                    {fileItem.docs?.map((file, fileIdx) => {
                      const { src, className } = getFileSrcAndClass(file.doc_file);
                      return (
                        <a
                          href={`${process.env.REACT_APP_API_ROOT}/${file.doc_file}`}
                          key={fileIdx}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="file-link"
                        >
                          <img
                            src={src}
                            alt="File"
                            className={`file-imgs ${className}`}
                          />
                        </a>
                      );
                    })}
                  </div>
                ))}
            </div> */}
            
            <TaskFilesSection taskDetails={taskDetails} getFileSrcAndClass={getFileSrcAndClass} updateTaskDetails={updateTaskDetails} selectedCard={selectedCard} offcanvasState={offcanvasState} setOffcanvasState={setOffcanvasState} fetchData={fetchData}/>
            {userMenu.visible && (
  <div
    ref={dropdownRef}
    className="column-options-menu"
    style={{
      top: userMenu.position.top,
      left: '285px',
      zIndex: 9999,
    }}
  >
    <div className="column-options-content">
      {userData?.user_data?.map((user) => (
        <div
          key={user.id}
          className="column-option"
          onClick={() => handleUserSelect(user)}
        >
          <img
            src={user?.avatar || `${staticPath}/${user?.file_name}`}
            alt="user"
            className="rounded-circle me-2"
            width="30"
            height="30"
          />
          <span>{user.name}</span>
        </div>
      ))}
    </div>
  </div>
)}
{multiDropdown.visible && multiDropdown?.activeCell && (
  <div
    ref={dropdownRef}
    className="multi-dropdown-menu"
    style={{
      top: `${multiDropdown.position.top - 18}px`,
      left: '285px',
      zIndex: 9999,
    }}
  >
    <div className="multi-dropdown-content">
      {multiDropdown?.dropdownOptions?.data?.map((option) => (
        <div
          key={option.id}
          className={`dropdown-optio ${Array.isArray(multiDropdown?.activeCell?.cell_data) && multiDropdown.activeCell.cell_data.some(cellItem => cellItem.id === option.id) ? 'selected' : ''}`}
          onClick={() => handleOptionSelect(option)}
        >
          {option.status_text}
        </div>
      ))}
    </div>
  </div>
)}


            {activeCell && dropdownPosition && (
              <div
                className=""
                ref={dropdownRef}
                style={{
                  position: 'absolute',
                  top: `${dropdownPosition.top - 50}px`,
                   left: '285px',
                  zIndex: "9999 !important",
                  width: '200px',
                  backgroundColor: 'white',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  borderRadius: '4px',
                  padding: '8px',
                }}
              >
                {activeCell.dropdown_options?.data?.map((option, idx) => (
                  <div
                    key={idx}
                    className="dropdown-option"
                    onClick={() => handleStatusSelect(option)}
                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '8px', transition: 'background-color 0.3s ease' }}
                  >
                    <div className="color-square" style={{ backgroundColor: option.status_color, width: '24px', height: '24px', marginRight: '8px', borderRadius: '4px' }}></div>
                    <span>{option.status_text}</span>
                  </div>
                ))}
              </div>
            )}

{showTimelinePicker && (
  <div
    className="timeline-picker"
    ref={dropdownRef}
    style={{ position: 'absolute', zIndex: 9999,top: `${dropdownPosition.top - 50}px`,
}}
  >
    <DateRangePicker
      ranges={[selectedTimelineRange]}
      onChange={handleTimelineChange}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      editableDateInputs={true}
      months={2}
      direction="horizontal"
    />
  </div>
)}


{showDatePicker && dropdownPosition && (
  <div
    className="date-picker"
    ref={dropdownRef}
    style={{
      position: 'absolute',
      top: `${dropdownPosition.top - 50}px`,
      //   left: dropdownPosition?.left,
      left: '285px',
      zIndex: 9999,
    }}
  >
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      inline
      calendarStartDay={1}
      shouldCloseOnSelect
    />
  </div>
)}

          </div>
        ) : (
          <p>No task details available.</p>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TaskDetailsOffcanvas;
