import { createSlice } from '@reduxjs/toolkit';
import { fetchFrames, deleteFrame, addFrame, updateFrame } from './workspaceActions';

const initialState = {
  frames: [],
  status: 'idle',
  error: null
};

const workspaceSlice = createSlice({
  name: 'frames',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFrames.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFrames.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.frames = action.payload.my_workspaces.concat(action.payload.public).concat(action.payload.shared);
      })
      .addCase(fetchFrames.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addFrame.fulfilled, (state, action) => {
        state.frames.push(action.payload);
      })
      .addCase(updateFrame.fulfilled, (state, action) => {
        const index = state.frames.findIndex(frame => frame.id === action.payload.id);
        if (index !== -1) {
          state.frames[index] = action.payload;
        }
      })
      .addCase(deleteFrame.fulfilled, (state, action) => {
        state.frames = state.frames.filter(frame => frame.id !== action.payload);
      });
  }
});

export default workspaceSlice.reducer;
