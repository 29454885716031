import React, { useState, useEffect } from "react";
import Board from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import { FaSearch } from "react-icons/fa";
import "../../assets/styles/kanban.css";
import { useParams } from "react-router-dom";
// import { Offcanvas } from "react-bootstrap";
import TaskDetailsOffcanvas from "./TaskDetailsOffcanvas";

const KanbanViewBoard = ({fetchData,globalSheetId,option,globalUserId,showDrawer, setShowDrawer,offcanvasState,setOffcanvasState,selectedCard,setSelectedCard,taskDetails,setTaskDetails,handleShow,isEditable,canDelete}) => {
  const [controlledBoard, setBoard] = useState({ columns: [] });
  const [groupBy, setGroupBy] = useState("dropdown");
      /*eslint-disable*/
  const [assignees, setAssignees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { frameId, sheetId } = useParams();
  const staticPath = `${process.env.REACT_APP_TOS_PATH}/includes/Pages/upload/`;
 
  // State to hold the selected card details
  const [searchQuery, setSearchQuery] = useState("");
 
console.log("isEditable",isEditable)
let newBoard
const groupColorMap = {}; // Store assigned colors for groups

const getColorForGroup = (groupName) => {
  if (!groupColorMap[groupName]) {
    // Assign a new color from the palette, or generate one if palette is exhausted
    const colorIndex = Object.keys(groupColorMap).length % colorPalette.length;
    groupColorMap[groupName] = colorPalette[colorIndex];
  }
  return groupColorMap[groupName];
};

  useEffect(() => {
    fetchInitialBoardData(groupBy);
  }, [groupBy,offcanvasState]);
  console.log("To Position:", controlledBoard,groupBy);
  // const handleOffcanvasChange = () => {
  //   setOffcanvasState(!offcanvasState); // Toggle or update state based on your logic
  // };

  const fetchInitialBoardData = async (groupByOption) => {
    setIsLoading(true); // Start loading
    try {
      const columnResponse = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/kanban-columns-group/${sheetId}/`);
      const columnsData = await columnResponse.json();
  
      if (groupByOption == "dropdown") {
        const selectedColumn = columnsData.data.find((col) => col.column_type === "dropdown");
        if (selectedColumn) {
          await fetchTasksData(selectedColumn.id);
        }
      } else if (groupByOption === "Assignee") {
        const selectedColumn = columnsData.data.find((col) => col.column_type === "users");
        if (selectedColumn) {
          await fetchAssigneeTasksData(selectedColumn.id);
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
      /*eslint-enable*/

  const fetchTasksData = async (groupId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/status-tasks/${groupId}/`);
      const tasksData = await response.json();

      const newBoard = {
        columns: tasksData.data.map((status) => ({
          id: status.id,
          title: status.status_text,
          cards: status.tasks.map((task) => ({
            id: task.id,
            description: task.task,
            group: task.group,
            statusColor: status.status_color,
            users: Array.isArray(task.assignee)
              ? task.assignee.map((user) => ({
                  id: user.id,
                  avatar: `${staticPath}/${user?.file_name}`,
                }))
              : [],
          })),
        })),
      };

      setBoard(newBoard);
    } catch (error) {
      console.error("Failed to fetch tasks data:", error);
    }
  };
  const colorPalette = [
    "#FFDDC1", // Light Peach
    "#C1E1FF", // Light Blue
    "#D1FFB0", // Light Green
    "#FFD1DC", // Light Pink
    "#E0BBE4", // Light Purple
    "#FFB3BA", // Light Coral
    "#FFDFBA", // Light Apricot
    "#FFFFBA", // Light Yellow
    "#BFFCC6", // Light Mint
    "#B4A7D6", // Light Lavender
    // Add more colors if needed
  ];
  
  const fetchAssigneeTasksData = async (groupId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/assignee-tasks/${groupId}/`);
      const assigneesData = await response.json();

       newBoard = {
        columns: assigneesData.data.map((assignee) => ({
          id: assignee.id,
          title: assignee.user,
          taskId: assignee.tasks.map((task) => task.id), 
          cards: assignee.tasks.map((task) => ({
            // id: assignee.id,
            id: task.id,
            description: task.task,
            group: task.group,
            title: assignee.user,
            // id: assignee.id,
            // taskId:task.id,
            file_name: assignee.file_name,


            // statusColor: task.status?.status_color || "#000",
            users: [
              {
                id: assignee.id,
                avatar: `${staticPath}/${assignee?.file_name}`,
              },
            ],
          })),
        })),
      };

      setBoard(newBoard);
    } catch (error) {
      console.error("Failed to fetch assignee tasks data:", error);
    }
  };
  const handleCardClick = async (card) => {
    setSelectedCard(card?.id); // Store selected card data
    setShowDrawer(true);   // Open the side drawer

    try {
      // Fetch details from the API using card.id
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}//hotwash/kanban-task/${card.id}/`);
      const data = await response.json();
      setTaskDetails(data); // Store fetched task details
      console.log("taskDetailstaskDetails",data)
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };
  // Close the drawer

 const handleCardMove = async (card, source, destination, fromPosition, toPosition) => {
  if (!destination) return;

  console.log("Card Being Moved:", card);
  console.log("Source:", source);
  console.log("Destination:", destination);
  console.log("From Position:", fromPosition);
  console.log("To Position:", toPosition);

  try {
    const updatedBoard = {
      source_user: destination?.fromColumnId,
      source_cell: source?.id,
      destination_status: fromPosition?.toColumnId
    };

    const updatedAssignee = {
      source_user: destination?.fromColumnId,
      source_cell: source?.id,
      destination_user: fromPosition?.toColumnId
    };

    let response;

    if (groupBy === "Assignee") {
      response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/update-assignee-task/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedAssignee),
      });
    } else if (groupBy === "dropdown") {
      response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/update-status-task/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedBoard),
      });
    }

    if (!response.ok) {
      throw new Error("Failed to update task status/assignee");
    }

    // Call fetchData after a successful API response
    fetchData();

  } catch (error) {
    console.error("Failed to move task:", error);
  }
};

  


  const handleGroupByChange = (event) => {
    setGroupBy(event.target.value);
  };

  const renderCard = (card = { users: [] }) => {
    const displayedUsers = card.users.slice(0, 3);
    const extraUsers = card.users.length > 3 ? card.users.length - 3 : 0;
    const cardColor = getColorForGroup(card.group); // Get color for the group


    return (
      <div
        onClick={() => handleCardClick(card)}
        style={{ borderLeft: `4px solid ${card.statusColor || "#000"}` }}
        className='custom-card'

      >
        <p className="card-description">{card.description}</p>
        <div className="card-footer">
          <span className="card-group" style={{ backgroundColor: cardColor,color:'black'}}>{card.group}</span>
          <div className="card-users">
            {displayedUsers.map((user) => (
              <img key={user.id} src={user.avatar} alt="User avatar" className="user-avatar" />
            ))}
            {extraUsers > 0 && <span className="extra-users">+{extraUsers}</span>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div     className='kanban-toolbar'
      >
        <div className="toolbar-left">
          <div className="search-container">
            <FaSearch className="search-icon" />
            <input
              type="text"
              className="search-bar"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="assignees">
            {assignees.slice(0, 5).map((assignee) => (
              <img key={assignee.id} src={assignee.avatar} alt="Assignee avatar" className="user-avatar" />
            ))}
            {assignees.length > 5 && <span className="extra-users">+{assignees.length - 5}</span>}
          </div>
        </div>
        <div className="group-by">
          <label htmlFor="group-by-select">Group By:</label>
          <select id="group-by-select" value={groupBy} onChange={handleGroupByChange}>
            <option value="dropdown">Status</option>
            <option value="Assignee">Assignee</option>
          </select>
        </div>
      </div>

      <div className="kanban-board-container">
      {isLoading ? (
      <div className="loading">Loading...</div>
    ) : (
      <div         className={!isEditable ? 'table-disabled ' : ''}
>
      <Board
        key={groupBy}
        onCardDragEnd={handleCardMove}
        disableColumnDrag
        renderCard={renderCard}
        initialBoard={controlledBoard}
      />
    </div>
    )}
      </div>
     <TaskDetailsOffcanvas showDrawer={showDrawer} handleShow={handleShow} taskDetails={taskDetails} setTaskDetails={setTaskDetails} selectedCard={selectedCard} fetchData={fetchData} setOffcanvasState={setOffcanvasState} offcanvasState={offcanvasState} globalUserId={globalUserId}/>

    </>
  );
};

export default KanbanViewBoard;
