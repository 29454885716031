import React, { useEffect, useState } from 'react';
import { Gantt, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css'; // Import the required styles for the Gantt chart
import axios from 'axios';
import "../../assets/styles/GanttBoard.css";
import TaskDetailsOffcanvas from './TaskDetailsOffcanvas';

const GanttBoard = ({
  timelineColumns,
  selectedColumnId,
  setSelectedColumnId,
  fetchData,
  globalSheetId,
  option,
  globalUserId,
  showDrawer,
  setShowDrawer,
  offcanvasState,
  setOffcanvasState,
  selectedCard,
  setSelectedCard,
  taskDetails,
  setTaskDetails,
  handleShow,
  isEditable,
  canDelete

}) => {
  const [tasks, setTasks] = useState([]);
  const [viewMode, setViewMode] = useState(ViewMode.Day); // Default view mode
  const [isLoading, setIsLoading] = useState(false);
    /*eslint-disable*/
  const [startDate, setStartDate] = useState(new Date(2020, 0, 1)); // Default start date
  const [endDate, setEndDate] = useState(new Date(2025, 11, 31)); // Default end date
  // const [fixedTaskId, setFixedTaskId] = useState(null); // New state to store fixedTask.id
    /*eslint-enable*/
  // Helper function to parse date with current year if the year is missing
const parseDate = (dateStr) => {
  const currentYear = new Date().getFullYear(); // Get the current year
  const dateRegex = /^(\d{1,2})\s([A-Za-z]{3})(?:\s(\d{4}))?$/; // Match day, month, and optionally year
  const match = dateStr.match(dateRegex);

  if (match) {
    const day = match[1];
    const month = match[2];
    const year = match[3] || currentYear; // Use current year if year is not provided

    // Construct the date string in a standard format
    const formattedDateStr = `${month} ${day} ${year}`;
    const parsedDate = new Date(`${formattedDateStr} ${year}`); // Ensure the correct year is used

    if (!isNaN(parsedDate.getTime())) {
      return parsedDate; // Return the valid parsed date
    }
  }
  
  // Default to current date if parsing fails
  return new Date();
};


  // Card click handler to open the side drawer and fetch task details
  const handleCardClick = async (cardId) => {
    setSelectedCard(cardId); // Store selected card ID (from fixedTask)
    setShowDrawer(true); // Open the side drawer

    try {
      // Fetch details from the API using the fixedTask.id
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/kanban-task/${cardId}/`);
      const data = await response.json();
      setTaskDetails(data); // Store fetched task details
      console.log("Fetched task details:", data);
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  // Format the data to match the Gantt task format
  const formatTasks = (taskData) => {
    return taskData.map((taskRow) => {
      const timelineTask = taskRow.find((t) => t.column_type === 'timeline');
      const fixedTask = taskRow.find((t) => t.column_type === 'fixed');

      if (timelineTask && fixedTask) {
        const [startStr, endStr] = timelineTask.cell_data.split(' - ');
        const startDate = parseDate(startStr.trim());
        const endDate = parseDate(endStr.trim());

        return {
          id: timelineTask.id, // Use timelineTask.id for Gantt chart
          name: fixedTask.cell_data,
          start: startDate,
          end: endDate,
          type: 'task',
          progress: 50,
          project: '',
          dependencies: '',
          fixedTaskId: fixedTask.id, // Store fixedTask.id for fetching task details
        };
      }
      return null;
    }).filter(Boolean);
  };

  // Fetch tasks for a specific timeline column
  const fetchTasks = async (columnId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/timeline-task/${columnId}/`);
      const formattedTasks = formatTasks(response.data);
      setTasks(formattedTasks);
    } catch (error) {
      console.error('Error fetching tasks', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to format dates as required
  const formatCellData = (startDate, endDate) => {
    const currentYear = new Date().getFullYear();

    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear() !== currentYear ? ` ${date.getFullYear()}` : ''; // Add year if not current year
      return `${day} ${month}${year}`;
    };

    const formattedStart = formatDate(startDate);
    const formattedEnd = formatDate(endDate);

    return `${formattedStart} - ${formattedEnd}`;
  };

  const handleTaskChange = async (task) => {
    const cellData = formatCellData(task.start, task.end);

    const updatedTask = {
      id: task.id,
      cell_data: cellData, // Pass the formatted cell_data instead of start and end
    };

    try {
      await axios.patch(`${process.env.REACT_APP_API_ROOT}/hotwash/cell/${task.id}/`, updatedTask);
      console.log('Task updated successfully:', updatedTask);
    } catch (error) {
      console.error('Error updating task', error);
    }
  };

  // Fetch tasks when the selected column changes
      /*eslint-disable*/
  useEffect(() => {
    if (selectedColumnId !== null) {
      setTasks([]);
      fetchTasks(selectedColumnId);

    }
  }, [selectedColumnId,showDrawer,offcanvasState]);
  
    /*eslint-enable*/
  // Dropdown change handler for timeline column selection
  const handleColumnChange = (event) => {
    setSelectedColumnId(event.target.value);
  };
// console.log("selectedColumnId",selectedColumnId)
  // Dropdown change handler for view mode selection
  const handleViewModeChange = (event) => {
    setViewMode(event.target.value);
  };

  return (
    <div className="gantt-board-container">
      <div className="dropdown-container">
        <label htmlFor="timeline-column">Column:</label>
        <select id="timeline-column" value={selectedColumnId} onChange={handleColumnChange}>
          {timelineColumns?.map((column) => (
            <option key={column.id} value={column.id}>
              {column.name}
            </option>
          ))}
        </select>

        <label htmlFor="view-mode">View Mode:</label>
        <select id="view-mode" value={viewMode} onChange={handleViewModeChange}>
          <option value={ViewMode.Day}>Day</option>
          <option value={ViewMode.Week}>Week</option>
          <option value={ViewMode.Month}>Month</option>
          <option value={ViewMode.Year}>Year</option>
          <option value={ViewMode.QuarterDay}>Quarter Day</option>
        </select>
      </div>

      {isLoading ? (
        <p>Loading tasks...</p>
      ) : tasks.length > 0 ? (
        <>
        
        <div         className={!isEditable ? 'table-disabled ' : ''}>

          <Gantt
            tasks={tasks}
            viewMode={viewMode}
            onDateChange={(task, children) => {
              handleTaskChange(task); // Call API to update task timeline
            }}
            onProgressChange={(task, children) => {
              // Optionally handle progress change here if needed
            }}
            onClick={(task) => {
              // Handle single-click to open task details or modal
              handleCardClick(task.fixedTaskId); // Pass fixedTaskId to handleCardClick
            }}
            
            // onDoubleClick={(task) => {
            //   // Handle double-click to open task details or modal
            //   handleCardClick(task.fixedTaskId); // Pass fixedTaskId to handleCardClick
            // }}
            // Ensure that the Gantt chart displays the full date range, even without tasks
            startDate={startDate}
            endDate={endDate}
          />
              </div>

          <TaskDetailsOffcanvas
            showDrawer={showDrawer}
            handleShow={handleShow}
            taskDetails={taskDetails}
            setTaskDetails={setTaskDetails}
            selectedCard={selectedCard}
            fetchData={fetchData}
            setOffcanvasState={setOffcanvasState}
            offcanvasState={offcanvasState}
            globalUserId={globalUserId}
            option={option}
            setSelectedCard={setSelectedCard}
          />
        </>
      ) : (
        <p>No tasks available for the selected column.</p>
      )}
    </div>
  );
};

export default GanttBoard;
