import React, { useState } from 'react';
import { 
     BiTrash, BiDuplicate, BiExport, BiArchive, BiSync, BiX, BiSearch 
} from "react-icons/bi";
import '../../assets/styles/TaskSelectionStripe.css';
import { toast } from 'react-toastify';

const TaskSelectionStripeSubrow = ({ selectedCount, selectedTaskIds, onClose, fetchData, sheetId, currentGroupId }) => {
    const [isMoveMenuOpen, setMoveMenuOpen] = useState(false);
    const [isGroupMenuOpen, setGroupMenuOpen] = useState(false);
    const [groups, setGroups] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    // Handle deleting selected tasks
    const handleDelete = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/delete-multiple-subrow/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ rows: selectedTaskIds }),
            });

            if (!response.ok) {
                throw new Error("Failed to delete tasks");
            }

            toast.success('Sub Tasks deleted successfully!');
            fetchData(); // Refresh the data after deletion
            onClose(); // Close the selection stripe

        } catch (error) {
            console.error("Error:", error);
            toast.error('Failed to delete rows!');
        }
    };

    // Handle the "Move" button click
    // const handleMoveClick = () => {
    //     setMoveMenuOpen(!isMoveMenuOpen);
    //     setGroupMenuOpen(false); 
    // };

    // Handle fetching groups and displaying the group menu
    const handleMoveToGroupClick = async () => {
        setGroupMenuOpen(true);
        setMoveMenuOpen(false); // Close the move menu

        if (!groups.length) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/sheet-groups/${sheetId}/`);
                const data = await response.json();
                setGroups(data.groups); // Assuming the response contains a `groups` array
            } catch (error) {
                console.error("Error fetching groups:", error);
                toast.error('Failed to load groups!');
            }
        }
    };

    // Handle group selection and moving tasks to the selected group
    const handleGroupSelect = async (groupId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/move-task-to-group/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ rows: selectedTaskIds, group_id: groupId }),
            });

            if (!response.ok) {
                throw new Error("Failed to move tasks");
            }

            toast.success('Tasks moved successfully!');
            fetchData(); // Refresh the data after moving tasks
            setMoveMenuOpen(false);
            setGroupMenuOpen(false);
            onClose(); // Close the selection stripe

        } catch (error) {
            console.error("Error moving tasks:", error);
            toast.error('Failed to move tasks!');
        }
    };

    // Handle search input change
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filtered group list based on search query and excluding the current group
    const filteredGroups = groups
        .filter(group => group.id !== currentGroupId)  // Exclude the current group
        .filter(group => group.name.toLowerCase().includes(searchQuery.toLowerCase())); // Apply search filter

    return (
        <div className="task-selection-stripe">
            <div className="selected-count-wrapper">
                <p className="selected-count">
                    {selectedCount} Sub task{selectedCount > 1 ? "s" : ""} selected
                </p>
                <div className="dots-wrapper">
                    {Array.from({ length: selectedCount }).map((_, index) => (
                        <div key={index} className="dot"></div>
                    ))}
                </div>
            </div>

            <div className="action-icons">
                {/* <div className="action-icon" onClick={handleMoveClick}>
                    <BiMove size={20} />
                    <span>Move</span>
                </div> */}
                {isMoveMenuOpen && (
                    <div className="move-menu">
                        <div className="move-option" onClick={handleMoveToGroupClick}>Move to Group</div>
                        <div className="move-option">Move to Sheet</div>
                    </div>
                )}
                {isGroupMenuOpen && (
                    <div className="group-menu">
                        <div className="menu-header">
                            <span className="menu-title">Choose Group</span>
                            <button className="back-button" onClick={() => setGroupMenuOpen(false)}>Back</button>
                        </div>
                        <div className="search-wrapper">
                            <input
                                type="text"
                                className="search-input"
                                placeholder="Search group..."
                                onChange={handleSearch}
                            />
                            <BiSearch size={20} className="search-icon" />
                        </div>
                        <ul className="group-list">
                            {filteredGroups.map((group) => (
                                <li
                                    key={group.id}
                                    className="group-item"
                                    onClick={() => handleGroupSelect(group.id)}
                                >
                                    {group.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="action-icon" onClick={handleDelete}>
                    <BiTrash size={20} />
                    <span>Delete</span>
                </div>
                <div className="action-icon">
                    <BiDuplicate size={20} />
                    <span>Duplicate</span>
                </div>
                <div className="action-icon">
                    <BiExport size={20} />
                    <span>Export</span>
                </div>
                <div className="action-icon">
                    <BiArchive size={20} />
                    <span>Archive</span>
                </div>
                <div className="action-icon">
                    <BiSync size={20} />
                    <span>Convert</span>
                </div>
            </div>

            <BiX size={24} className="close-icon" onClick={onClose} />
        </div>
    );
};

export default TaskSelectionStripeSubrow;
