// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-container {
  flex: 1 1;
  background-color: #eceff8;
}
.Maincontent{
  width: calc(100% - 283px);
  /* padding: 20px; */
  /* height:94vh */
 
}

.content-row {
  display: flex;
  flex-wrap: nowrap;
  /* height: 100vh; */
}

.sidebar-col {
  /* background-color: #f8f9fa; */
  padding: 0;
  border-right: 1px solid #dee2e6;
  /* height: 100vh; */
}

.content-col {
  padding-top: 10px; /* Add padding for spacing */
  padding-left: 20px; /* Add padding for spacing */
max-height:90vh;
  background-color: #eceff8; /* Ensure a different background color if needed */
  /* height: 100vh; */
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,SAAO;EACP,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;;AAElB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,UAAU;EACV,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB,EAAE,4BAA4B;EAC/C,kBAAkB,EAAE,4BAA4B;AAClD,eAAe;EACb,yBAAyB,EAAE,kDAAkD;EAC7E,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":["/* App.css */\n.app-container {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n}\n\n.main-container {\n  flex: 1;\n  background-color: #eceff8;\n}\n.Maincontent{\n  width: calc(100% - 283px);\n  /* padding: 20px; */\n  /* height:94vh */\n \n}\n\n.content-row {\n  display: flex;\n  flex-wrap: nowrap;\n  /* height: 100vh; */\n}\n\n.sidebar-col {\n  /* background-color: #f8f9fa; */\n  padding: 0;\n  border-right: 1px solid #dee2e6;\n  /* height: 100vh; */\n}\n\n.content-col {\n  padding-top: 10px; /* Add padding for spacing */\n  padding-left: 20px; /* Add padding for spacing */\nmax-height:90vh;\n  background-color: #eceff8; /* Ensure a different background color if needed */\n  /* height: 100vh; */\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
