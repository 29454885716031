import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/common/Header';
import Sidebar from './components/common/Sidebar';
import Dashboard from './components/Dashboard/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import MainFramesList from './components/MainFrame/MainFramesList';
import axios from 'axios';
import SheetPage from './components/Sheet/SheetPage';
import { ThemeProvider } from './components/common/ThemeContext';
import Cookies from 'js-cookie'; // Import js-cookie to handle cookies
// import KanbanViewBoard from './components/common/KanbanViewBoard';

const App = () => {
  const [user, setUser] = useState(null);
      /*eslint-disable*/
  const [deptName, setDeptName] = useState('');
  const [divName, setDivName] = useState('');
      /*eslint-enable*/
  const [maindeptName, setMainDeptName] = useState('');
  const [sidebartrue,setsidebartrue] = useState(false);
  // const[ssotoken,setSsotoken]=useState();
  function getCookieValue(name) {
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
  
  const ssoToken = getCookieValue('sso_token');
  console.log("ssoToken:", ssoToken);
        /*eslint-disable*/

  useEffect(() => {
    console.log("ssoToken",document.cookie);

     const ssoToken = Cookies.get('sso_token');
     
    if (ssoToken) {
      fetchUser(ssoToken);
    }else {
      const currentUrl = window.location.href;
      const queryParams = new URLSearchParams({ next: currentUrl }).toString();
      const redirectUrl = `${process.env.REACT_APP_REDIRECT_URL}?${queryParams}`;
      
      window.location.href = redirectUrl;
    }
  }, []);
    /*eslint-enable*/
  const fetchUser = async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/user-detail/${token}`);
      const userData = response.data.user;
      setUser(userData);
      setDeptName(userData.department_name);
      setDivName(userData.division_name);
      setMainDeptName(userData.main_department);

      if (!localStorage.getItem("HDeptName")) {
        localStorage.setItem("HDeptName", userData.department_name);
      }
      if (!localStorage.getItem("HDivName")) {
        localStorage.setItem("HDivName", userData.division_name);
      }
      if (!localStorage.getItem("HDept")) {
        localStorage.setItem("HDept", userData.departmentId);
      }
      if (!localStorage.getItem("HDiv")) {
        localStorage.setItem("HDiv", userData.userid);
      }

      fetchFrames(userData.departmentId, userData.divisionId,token);
    } catch (error) {
      console.error('Error fetching user:', error);
      // If there's an error (e.g., invalid token), redirect to login page
      // window.location.href = 'http://localhost/latest/TOS/index.php';
    }
  };

  const fetchFrames = async (DeptId, DivId,UserID) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/filter-workspaces/${DeptId}/${UserID}/`);
      console.log("Frames Data:", response.data);
      // Handle frames data if needed
    } catch (error) {
      console.error('Error fetching frames:', error);
    }
  };

  return (
    <ThemeProvider>
      <Router>
        <div className="app-container">
          <ToastContainer />
          <div className="main-container">
            <div className="content-row">
              <div style={{ width: '283px' }}>
                <Sidebar user={user} maindeptName={maindeptName} sidebartrue={sidebartrue} setsidebartrue={setsidebartrue}/>
              </div>
              <div className='Maincontent'>
                <Header user={user} />
                <Routes>
                  <Route path="/" element={<MainFramesList user={user} setsidebartrue={setsidebartrue} sidebartrue={sidebartrue}/>} />
                  <Route path="/home" element={<Dashboard />} />
                  <Route path="/frame/:frameId/:sheetName/:sheetId" element={<SheetPage user={user} />} />
                  {/* <Route path="/kanban" element={<KanbanViewBoard />}/> */}
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
