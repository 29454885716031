import { createSlice } from '@reduxjs/toolkit';

export const departmentSlice = createSlice({
  name: 'department',
  initialState: {
    value: 0,
  },
  reducers: {
    setValue: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setValue } = departmentSlice.actions;

export default departmentSlice.reducer;