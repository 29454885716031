import React, { useState, useEffect, useRef } from 'react';
import '../../assets/styles/custommodal.css';
import { FaArrowDownLong } from 'react-icons/fa6';
import { BsFillTrashFill } from 'react-icons/bs';
import { AiFillFileText, AiOutlineClockCircle, AiOutlineTag } from 'react-icons/ai';
import axios from 'axios';
import moment from 'moment';
import Switch from 'react-switch';
import { BiDotsHorizontal } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

const AutomationModal = ({ isOpen, onClose ,globalSheetId,isEditable,canDelete}) => {
  const [showAddAutomation, setShowAddAutomation] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [status, setStatus] = useState('');
  const [something, setSomething] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [showSomethingMenu, setShowSomethingMenu] = useState(false);
  const [showThenDoThisMenu, setShowThenDoThisMenu] = useState(false); // New state
  const [statusOptions, setStatusOptions] = useState([]);
  const [payloaddate,setPaloaddate] = useState(); 
  const [whenPayload,setWhenPaload] = useState(); 
const [whenDate,setWhenDate]=useState(false);
  const [somethingOptions, setSomethingOptions] = useState([]);
  const [thenDoThisOption, setThenDoThisOption] = useState(''); // New state for "Then Do This"
  const [showDateMenu, setShowDateMenu] = useState(false); // State for showing date menu
  const [showGroupMenu, setShowGroupMenu] = useState(false); // State for showing date menu
  const [dateOptions, setDateOptions] = useState([]); // State for storing date column options
  const [selectedColumnDateId, setSelectedDateColumnId] = useState(''); // State for the selected date column
  const [thendate, setThenDate] = useState('');
  const [thenGroup, setThenGroup] = useState('');
  const [showUserMenu, setShowUserMenu] = useState(false); // State for showing date menu
  const [notifyId, setNotifyId] = useState(''); // State for the selected date column

  const [selectedColumnStatusId, setSelectedDateStatusId] = useState(''); // State for the selected date column
  const [selectedColumnStatusIndex, setSelectedColumnStatusIndex] = useState(''); // State for the selected date column
  const [thencolumnId,setthencolumnId] = useState('');
  const [thencolumnIndex,setthencolumnIndex] = useState('');
  const [showThenStatusMenu, setShowThenStatusMenu] = useState(false);
  const [showThenSomethingMenu, setShowThenSomethingMenu] = useState(false);
  const [thenstatus, setThenStatus] = useState('');
  const [thensomething, setThenSomething] = useState();
  const [thensomethingid,setthensomethingid] = useState();
  const [actions,setActions] = useState();
  const [actionText,setActionText] = useState();
  const [groupOptions, setGroupOptions] = useState('');
  const [userOptions, setUserOptions] = useState('');
  const [columnOptions, setColumnOptions] = useState('');
  const [notifyMenu, setNotifyMenu] = useState('');
const [thenColumn,setThenColumn]=useState('');
  const [thenUser, setThenUser] = useState('');
const[showColumnMenu,setShowColumnMenu]=useState(false);
const[selectedGroupId,setSelectedGroupId]=useState('')
  const [WhenActions,setWhenActions] = useState('');
  const [showWhenDateMenu,setshowWhenDateMenu] = useState();
  const [showWhenDaysMenu,setshowWhenDaysMenu] = useState();
  const [whenDays,setWhenDays] = useState(1);

  const [automations, setAutomations] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [menuVisible, setMenuVisible] = useState(null);
  const menuRef = useRef(null);
      /*eslint-disable*/
  useEffect(() => {
    fetchAutomations();
  }, []);
      /*eslint-enable*/
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You need admin or edit access to perform this action.
    </Tooltip>
  );
  const fetchAutomations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/automations/${globalSheetId}/`);
      setAutomations(response.data.automations);
      // setLoading(false);
    } catch (error) {
      console.error('Error fetching automations:', error);
      // setLoading(false);
    }
  };
  const handleToggle = async (id, currentStatus) => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_ROOT}/hotwash/update-automation/${id}/`, {
        active: !currentStatus
      });
      fetchAutomations();

    } catch (error) {
      console.error('Error updating automation:', error);
    }
  };
  const timeAgo = (date) => moment(date).fromNow();
  const handleUserMenuClick = () => {
    setShowUserMenu(true);
    setThenColumn("Member")
    setNotifyMenu(false)

    fetchUserOptions()
  };
  const handleColumnsClick = () => {
    setShowColumnMenu(true);
    setThenColumn("Column")

    setNotifyMenu(false)
    fetchColumnOptions()
  };
  
    const handleDelete = async(id) => {
    try {
      await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/delete-automation/${id}/`);
      setAutomations(prevState => prevState.filter(auto => auto.id !== id));
      toast.success('Automation deleted successfully!');

      fetchAutomations()
    } catch (error) {
      console.error('Error deleting automation:', error);
    }
  };
  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisible(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleMenuClick = (id) => {
    setMenuVisible(menuVisible === id ? null : id);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
        setshowWhenDateMenu(false)
        setShowUserMenu(false)
        setShowColumnMenu(false)
        setNotifyMenu(false)
        setShowStatusMenu(false);
        setShowSomethingMenu(false);
        setShowThenDoThisMenu(false); 
        setShowThenSomethingMenu(false)// Hide "Then Do This" menu
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  /*eslint-disable*/

  useEffect(() => {
    console.log("ujhgfdghjiko",thenDoThisOption,thenColumn)

    if (thenDoThisOption.includes('Set Date')) {
      setActionText(` Set ${thendate ? thendate : 'Date'} to Today`);
      setActions('set_today');

    } else if (thenDoThisOption === 'Move Item to Group') {
      setActions(`move_item`);
      setActionText(`Move Item To${thenGroup ? thenGroup: 'Group'} `);

    } else if (thenDoThisOption === 'Notify' && thenColumn==='Column') {
      setActions(`column_date_arrives`);


      setActionText(`Notify to the  ${thenUser ? thenUser: 'Person'} column users `);

    }else if (thenDoThisOption === 'Notify' && thenColumn ==='Member') {
      setActions(`member_date_arrives`);

      setActionText(`Notify to the  ${thenUser ? thenUser: 'Person'} `);

    }
    else if (thenDoThisOption === 'Change Status'&& selectedOption !=='Item Created') {
      setActionText(` set ${thenstatus ? thenstatus : 'Status'} to ${thensomething ? thensomething : 'Something'}`);
      setActions('change_status');

    }
    else if (thenDoThisOption === 'Change Status' && selectedOption==='Item Created') {
      setActionText(`Then set ${thenstatus ? thenstatus : 'Status'} to ${thensomething ? thensomething : 'Something'}`);
      setActions('item_created');

    } else {
      setActions('Then do this');
      setActionText('Then do this')
    }
  }, [thenDoThisOption, thendate, thenstatus, thensomething,thenColumn]);
    /*eslint-enable*/

  useEffect(() => {
    if (selectedOption.includes('Status Changes to') || selectedOption.includes(`${status} Changes to`)){
      setWhenActions(`When ${status ? status : 'Status'} Changed to ${something ? something : 'Something'}`);
    } else if (selectedOption === 'Date Arrives') {
      setWhenActions(`Date Arrives`);
    } else if (selectedOption === 'Item Created') {
      setWhenActions(`When Item Created`);
    } else {
      setWhenActions('When This Happens');
    }
  }, [selectedOption,status,something,thenGroup,thenUser]);
 
  const fetchStatusOptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/all-status-columns/${globalSheetId}/`);
      setStatusOptions(response.data?.all_status);
    } catch (error) {
      console.error('Error fetching status options:', error);
    }
  };
  const fetchGroupOptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/all-sheet-groups/${globalSheetId}/`);
      setGroupOptions(response.data?.groups);
    } catch (error) {
      console.error('Error fetching status options:', error);
    }
  };
  const fetchUserOptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/sch/get_user_data/1/`);
      setUserOptions(response?.data?.user_data);
    } catch (error) {
      console.error('Error fetching status options:', error);
    }
  };
  const fetchColumnOptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/all-user-columns/${globalSheetId}/`);
      setColumnOptions(response?.data?.all_users);
    } catch (error) {
      console.error('Error fetching status options:', error);
    }
  };
  const fetchSomethingOptions = async (optionId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/status-column-options/${optionId}/`);
      setSomethingOptions(response.data?.statuses);
    } catch (error) {
      console.error('Error fetching something options:', error);
    }
  };
  const fetchDateOptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ROOT}/hotwash/all-date-columns/${globalSheetId}/`); // Adjust the API endpoint as needed
      setDateOptions(response.data?.all_status); // Set date column options
    } catch (error) {
      console.error('Error fetching date options:', error);
    }
  };
  
  if (!isOpen) return null;

  const handleAddAutomationClick = () => {
    setShowAddAutomation(true);
  };

  const handleBackClick = () => {
    setShowAddAutomation(false);
    setSelectedOption('');
    setStatus('');
    setSomething('');
    setThenDoThisOption(''); // Clear "Then Do This" option
  };

  const handleStatusOptionClick = (option) => {
    setStatus(option.name);
    setSelectedDateStatusId(option?.id)
    setSelectedColumnStatusIndex(option?.column_index)
    setShowStatusMenu(false);
    fetchSomethingOptions(option.id);
  };
  const handleThenStatusOptionClick = (option) => {
    setThenStatus(option.name);
    setthencolumnId(option?.id)
    setthencolumnIndex(option?.column_index)
    setShowThenStatusMenu(false);
    fetchSomethingOptions(option.id);
  };
  const handleUserOptionClick = (option) => {
    // setThenColumn("Member")

    setThenUser(option.name);
    setNotifyId(option?.id)
    setShowUserMenu(false)
    // setThenColumn("Member")

    setNotifyMenu(false)
    // setThenDoThisOption(option.name);

    // setthencolumnId(option?.id)
    // setthencolumnIndex(option?.column_index)
    // setShowThenStatusMenu(false);
    // fetchSomethingOptions(option.id);
  };
  const handleColumnOptionClick = (option) => {
    // setThenColumn("Column")

    setThenUser(option.name);
    setNotifyId(option?.id)
    // setThenColumn("Column")

    setShowColumnMenu(false)
    setNotifyMenu(false)
    // setThenDoThisOption(option.name);

    // setthencolumnId(option?.id)
    // setthencolumnIndex(option?.column_index)
    // setShowThenStatusMenu(false);
    // fetchSomethingOptions(option.id);
  };
  const handleDateOptionClick = (option) => {
    console.log("travelling",option)
    setThenDate(option.name); // Set selected date column
    setSelectedDateColumnId(option?.id)
    setShowDateMenu(false); // Hide date menu
  };
  const handleWhenDateOptionClick = (option) => {
    console.log("travelling",option)
    setWhenDate(option.name); // Set selected date column
    setSelectedDateColumnId(option?.id)
    setshowWhenDateMenu(false); // Hide date menu
  };
  const handleWhenDaysOptionClick = (e) => {
    setWhenDays(e); // Set selected date column
    // setSelectedDateColumnId(option?.id)
    setshowWhenDaysMenu(false); // Hide date menu
  };  const handleGroupOptionClick = (option) => {
    console.log("travelling",option)
    setThenGroup(option.name); // Set selected date column
    setSelectedGroupId(option?.id)
    setShowGroupMenu(false); // Hide date menu
  };
  const handleSomethingOptionClick = (option) => {
    setSomething(option?.status_text);
    setSelectedOption(`When ${status} Changes to ${option.status_text}`);
    setShowSomethingMenu(false);
  };
  const handleThenSomethingOptionClick = (option) => {
    console.log("travelling123",option)
    setthensomethingid(option?.id);
    setThenSomething(option?.status_text);
    // setSelectedOption(`When ${status} Changes to ${option.status_text}`);
    setShowThenSomethingMenu(false);
  };
  
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === 'Status Changes to Something') {
      setWhenPaload('Status Changes to Something')

      fetchStatusOptions();
      setShowMenu(false);
      setShowStatusMenu(true);
    } else if (option === 'Item Created') {
      setWhenPaload('Item Created')

      setSelectedOption(option);
      setShowMenu(false);
    }else if (option === 'Date Arrives') {
      setWhenPaload('Date Arrives')
      setshowWhenDateMenu(true)

      setSelectedOption(option);
      fetchDateOptions(); // Fetch date options

      setShowMenu(false);
    }
  };
  const handleThenDoThisOptionClick = (option) => {
    console.log("ujhgfdghjiko",option)
    setThenDoThisOption(option);
    fetchUserOptions()
    fetchColumnOptions()
    if (option === 'Set Date') {
      setPaloaddate('set_today')
      fetchDateOptions(); // Fetch date options
      setShowDateMenu(false); // Ensure date menu is hidden initially
    }else if(option === 'Change Status'){
      fetchStatusOptions();

      setPaloaddate('Change Status');
      setShowDateMenu(false);
    }else if(option === 'Move Item to Group'){
      setPaloaddate('Move Item to Group');
      fetchGroupOptions()
      setShowDateMenu(false);
    }
    else if(option === 'Notify'){
      setPaloaddate('Notify');
      setNotifyMenu(true)
      setThenDoThisOption(option);

      // fetchUserOptions()
      setShowDateMenu(false);
    }
     else {
      setThenDoThisOption(option);
      setShowDateMenu(false); // Hide date menu for other options
    }
    setShowThenDoThisMenu(false); // Hide the "Then Do This" menu after selection
  };
  const handleDeleteClick = () => {
    setSelectedOption('');
    setStatus('');
    setSomething('');
  };
 const handledeletethen = () =>{
  setThenDoThisOption('')
  setSelectedOption('');
 }

 
  
  
  
  const renderWhenText = () => {
    if (selectedOption.includes('Status Changes to') || selectedOption.includes(`${status} Changes to`)) {
      return (
        <>
          When{' '}
          <span
            className={`clickable-text ${status ? 'status-text' : ''}`}
            onClick={() => setShowStatusMenu(!showStatusMenu)}
          >
            {status || 'Status'}
          </span>{' '}
          Changes to{' '}
          <span
            className={`clickable-text ${something ? 'something-text' : ''}`}
            onClick={() => setShowSomethingMenu(!showSomethingMenu)}
          >
            {something || 'Something'}
          </span>
        </>
      );
    } else if (selectedOption === 'Date Arrives') {
      return (
        <>
          <span
            className={`clickable-text ${whenDays ? 'status-text' : ''}`}
            onClick={() => setshowWhenDaysMenu(!showWhenDaysMenu)}
          >
            {`${whenDays} Days Before` || 'When'}
          </span>{' '}
          <span
            className={`clickable-text ${whenDate ? 'something-text' : ''}`}
            onClick={() => setshowWhenDateMenu(!showWhenDateMenu)}
          >
            {whenDate || 'Date'}
          </span>
          {/* <span className="clickable-text date-text">
            Date
          </span>{' '} */}
          Arrives
        </>
      );
    } else if (selectedOption === 'Item Created') {
      return (
        <>
          When Item Created
        </>
      );
    } else {
      return (
        <>
          When This Happens
          <FaArrowDownLong className="arrow-down" />
        </>
      );
    }
  };
  const renderThenText = () => {
    console.log("thenDoThisOptionthenDoThisOption",thenDoThisOption)
    if (thenDoThisOption.includes('Set Date')) {
      // setActions(`Then Set ${thendate ? thendate : 'Date' } to Today `)
      return (
        <>
          Then Set{' '}
          <span
            className={`clickable-text ${thendate ? 'status-text' : ''}`}
            onClick={() => setShowDateMenu(!showDateMenu)}
          >
            {thendate || ' Date'}
          </span>{' '}
          to Today{' '}
         
        </>
      );
    } else if (thenDoThisOption === 'Move Item to Group') {
      // setActions(`Move Item To Group`)
      return (
        <>
          Move Item to{' '}
          <span className={`clickable-text ${thenGroup ? 'status-text' : ''}`}
           onClick={() => setShowGroupMenu(!showGroupMenu)}
          >
             {thenGroup || ' Group'}
           
          </span>{' '}
        </>
      );
    }else if (thenDoThisOption === 'Notify') {
      // setActions(`Move Item To Group`)
      return (
        <>
         Then Notify{' '}
          <span className={`clickable-text ${thenUser ? 'status-text' : ''}`}
           onClick={() => setNotifyMenu(!notifyMenu)}
          >
             {thenUser || '-'}
           
          </span>{' '}
        </>
      );
    } else if (thenDoThisOption === 'Change Status') {
      // setActions(`Then set ${thenstatus ? thenstatus : 'Status'} to ${something ? something : 'Something'}`)
      return (
        <>
        Then set{' '}
        <span
          className={`clickable-text ${thenstatus ? 'status-text' : ''}`}
          onClick={() => setShowThenStatusMenu(!showThenStatusMenu)}
        >
          {thenstatus || 'Status'}
        </span>{' '}
        to{' '}
        <span
          className={`clickable-text ${something ? 'something-text' : ''}`}
          onClick={() => setShowThenSomethingMenu(!showThenSomethingMenu)}
        >
          {thensomething || 'Something'}
        </span>
      </>
      );
    } else {
      return (
        <>
        Then do this
        </>
      );
    }
  };
  const whenhappen = () => {
    if(whenPayload === 'Item Created'){
      return {
       
     }
    }
    else if(whenPayload === 'Status Changes to Something'){
      return {
        column_id:selectedColumnStatusId,
        column_index: selectedColumnStatusIndex,
        value: something
     }}else if(whenPayload === 'Date Arrives' && thenColumn==='Column'){
      return {
        days:whenDays,
        notify_column: notifyId,
        date_column: selectedColumnDateId
     }}else if(whenPayload === 'Date Arrives' && thenColumn==='Member'){
      return {
        days:whenDays,
        member: notifyId,
        date_column: selectedColumnDateId
     }}
  
  }
  const whendothen = () =>{
    if(payloaddate === 'set_today'){
      return {
        column_id:selectedColumnDateId 
     }
    }
    else if(payloaddate === 'Change Status'){
      return{
        column_id:thencolumnId,
        column_index: thencolumnIndex,
        status_id: thensomethingid
      }
     
    }else if(payloaddate === 'Notify'){
      return{
       
      }
     
    }
    else if(payloaddate === 'Move Item to Group'){
      return{
        group_id: selectedGroupId

      }
     
    }
   
  }
  console.log("thencoulmmmmmm",thenColumn)
  const handleCreateAutomationClick = async () => {
    const payload = {
      sheet_id: globalSheetId,
      event: WhenActions,
      event_type: WhenActions === 'Date Arrives' ? 'Reminder For the member' : 'dropdown',
      action: actions,
      action_text:actionText,
      user_id:12,
      when_happen: whenhappen(),
      do_then: whendothen()
    };
    console.log("santhusjbjcs",payload)
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/set-automation/`, payload);
      toast.success("Automation Added")
      setShowAddAutomation(false)
      fetchAutomations()
      setThenColumn("")
      console.log('Automation created successfully:', response.data);
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error('Error creating automation:', error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <div className="automation-modal-overlay" onClick={onClose}>
      <div className="automation-modal-content" onClick={(e) => e.stopPropagation()}>
        {!showAddAutomation ? (
          <>
            <div className="modal-header">
              <h2 className="modal-title">Automation Hub</h2>
              <button className="btn-close" onClick={onClose}>
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
            <div className="modal-actions">
            <OverlayTrigger
      placement="top" // Tooltip will be shown on top
      overlay={!canDelete ? renderTooltip : <></>} // Show tooltip only when not editable
    >
      <span className="d-inline-block">
        <Button
          className="btn-add-automation"
          onClick={canDelete ? handleAddAutomationClick : null} // Disable click when not editable
          disabled={!canDelete} // Disable button when not editable
          style={{ pointerEvents: !canDelete ? 'none' : 'auto' }} // Allow tooltip to show when button is disabled
        >
          Add Automation
        </Button>
      </span>
    </OverlayTrigger>
              <div className="search-filter-container">
                <input type="text" placeholder="Search..." className="input-search" />
                <div className="filter-icon">
                  <i className="bi bi-funnel"></i>
                  <span className="filter-text">Filter</span>
                </div>
              </div>
            </div>
            <div className="automation-list">
      {automations.map(automation => (
        <div className="automation-card" key={automation.id}>
          <div className="automation-content">
            <i className="bi bi-calendar-event"></i>
            <span className="automation-text">
              {automation.event} Then {automation.action_text}
            </span>
          </div>
          <div className="switch-container">
            <div className="switch-wrapper">
            <OverlayTrigger
      placement="top" // Tooltip will be shown on top
      overlay={!isEditable ? renderTooltip : <></>} // Show tooltip only when not editable
    >
      <span className="d-inline-block" style={{ pointerEvents: !isEditable ? 'none' : 'auto' }}>
        <span className="switch-text">Off</span>
        <Switch
          onChange={isEditable ? () => handleToggle(automation.id, automation.active) : null} // Only call handleToggle if editable
          checked={automation.active}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#4caf50"
          offColor="#f44336"
          className="react-switch"
          disabled={!isEditable} // Disable the switch visually when not editable
        />
        <span className="switch-text">On</span>
      </span>
    </OverlayTrigger>
              <div className="menu-container" ref={menuRef}>
            <BiDotsHorizontal
              className="menu-icon"
              onClick={() => handleMenuClick(automation.id)}
            />
            {menuVisible === automation?.id && (
              <div className="menu">
                <OverlayTrigger
      placement="top" // Tooltip will be shown on top
      overlay={!canDelete ? renderTooltip : <></>} // Show tooltip only when not editable
    >
      <span className="d-inline-block">
        <div
          className={!canDelete ? 'table-disabled menu-item' : 'menu-item'}
          onClick={() => canDelete && handleDelete(automation?.id)} // Only call handleDelete if editable
          style={{ pointerEvents: !canDelete ? 'none' : 'auto' }} // Allow tooltip to show when disabled
        >
          <i className="bi bi-trash"></i> Delete Automation
        </div>
      </span>
    </OverlayTrigger>
              </div>
            )}
          </div>
            </div>
            <div className="time-text">Updated :{timeAgo(automation.updated_at)}</div>
          </div>
         
        </div>
      ))}
    </div>
          </>
        ) : (
          <div className="add-automation-content">
            <div className="back-header" onClick={handleBackClick}>
              <i className="bi bi-arrow-left"></i> Back
            </div>
            <div className="options-section">
              <div className="option-container">
                <div className="option-header" onClick={() => setShowMenu(!showMenu)}>
                  <h2>
                    {renderWhenText()}
                    {selectedOption && (
                      <BsFillTrashFill className="delete-icon" onClick={handleDeleteClick} />
                    )}
                  </h2>
                </div>
                {showMenu && !selectedOption && (
                  <div className="option-menu" ref={menuRef}>
                    <div className="menu-item" onClick={() => handleOptionClick('Status Changes to Something')}>
                      <AiOutlineTag className="menu-icon" />
                      Status Changes to Something
                    </div>
                    <div className="menu-item" onClick={() => handleOptionClick('Item Created')}>
                      <AiOutlineClockCircle className="menu-icon" />
                      Item Created
                    </div>
                    <div className="menu-item" onClick={() => handleOptionClick('Date Arrives')}>
                      <AiFillFileText className="menu-icon" />
                      Date Arrives
                    </div>
                  </div>
                )}
                {showStatusMenu && (
                  <div className="option-menu" ref={menuRef}>
                    {statusOptions?.map((option) => (
                      <div className="menu-item" key={option.id} onClick={() => handleStatusOptionClick(option)}>
                        <AiOutlineTag className="menu-icon" />
                        {option.name}
                      </div>
                    ))}
                  </div>
                )}
                {showWhenDateMenu && (
                  <div className="option-menu" ref={menuRef}>
                  {dateOptions.map((option) => (
                    <div className="menu-item" key={option.id} onClick={() => handleWhenDateOptionClick(option)}>
                      {option.name}
                    </div>
                  ))}
                </div>
                )}
                {showWhenDaysMenu && (
                  <div className="option-menu" ref={menuRef}>
                  <input
    type="number"
    className="days-input"
    placeholder="Enter number of days"
    onChange={(e) => handleWhenDaysOptionClick(e.target.value)}
    min="1" // Optional: Sets the minimum value
  />
                </div>
  
)}

                
                {showSomethingMenu && (
                  <div className="option-menu" ref={menuRef}>
                    {somethingOptions.map((option) => (
                      <div
                        className="menu-item"
                        key={option.id}
                        onClick={() => handleSomethingOptionClick(option)}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div
                          className="status-color-indicator"
                          style={{ backgroundColor: option.status_color, width: 20, height: 20, borderRadius: '50%', marginRight: 10 }}
                        ></div>
                        <span>{option.status_text}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="option-container">
                <div className="option-header"  onClick={() => setShowThenDoThisMenu(!showThenDoThisMenu)}>
                  <h2>
                    {renderThenText()}
                    {thenDoThisOption && (
                      <BsFillTrashFill className="delete-icon" onClick={handledeletethen} />
                    )}
                  </h2>
                </div>
                {console.log("santhudjdff",WhenActions)}
                {WhenActions!=='When This Happens' && showThenDoThisMenu && !thenDoThisOption && (
                   <div className="option-menu" ref={menuRef}>
                    {WhenActions !== 'Date Arrives' && 
                     <div className="menu-item" onClick={() => handleThenDoThisOptionClick('Set Date')}>
                     Set Date
                   </div>
                    }
                   {
                    WhenActions !== 'When Item Created' &&
                    <div className="menu-item" onClick={() => handleThenDoThisOptionClick('Move Item to Group')}>
                     Move Item to Group
                   </div>
                   }
                   <div className="menu-item" onClick={() => handleThenDoThisOptionClick('Change Status')}>
                     Change Status
                   </div>
                   <div className="menu-item" onClick={() => handleThenDoThisOptionClick('Notify')}>
                     Notify
                   </div>
                 </div>
                )}
                {notifyMenu && (
                   <div className="option-menu" ref={menuRef}>
                   <div className="menu-item" onClick={handleColumnsClick}>
      Columns
    </div>
    <div className="menu-item" onClick={handleUserMenuClick}>
      Member
    </div>
                   
                 </div>
                )}
                {showDateMenu && (
                  <div className="option-menu" ref={menuRef}>
                  {dateOptions.map((option) => (
                    <div className="menu-item" key={option.id} onClick={() => handleDateOptionClick(option)}>
                      {option.name}
                    </div>
                  ))}
                </div>
                )}
                  {showGroupMenu && (
                  <div className="option-menu" ref={menuRef}>
                  {groupOptions?.map((option) => (
                    <div className="menu-item" key={option.id} onClick={() => handleGroupOptionClick(option)}>
                      {option.name}
                    </div>
                  ))}
                </div>
                )}
                {showUserMenu && (
                  <div className="option-menu" ref={menuRef}>
                  {userOptions?.map((option) => (
                    <div className="menu-item" key={option?.id} onClick={() => handleUserOptionClick(option)}>
                      {option?.name}
                    </div>
                  ))}
                </div>
                )}
                {showColumnMenu && (
                  <div className="option-menu" ref={menuRef}>
                  {columnOptions?.map((option) => (
                    <div className="menu-item" key={option?.id} onClick={() => handleColumnOptionClick(option)}>
                      {option?.name}
                    </div>
                  ))}
                </div>
                )}
                 {showThenStatusMenu && (
                  <div className="option-menu" ref={menuRef}>
                    {statusOptions.map((option) => (
                      <div className="menu-item" key={option.id} onClick={() => handleThenStatusOptionClick(option)}>
                        <AiOutlineTag className="menu-icon" />
                        {option.name}
                      </div>
                    ))}
                  </div>
                )}
                {showThenSomethingMenu && (
                  <div className="option-menu" ref={menuRef}>
                    {somethingOptions.map((option) => (
                      <div
                        className="menu-item"
                        key={option.id}
                        onClick={() => handleThenSomethingOptionClick(option)}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div
                          className="status-color-indicator"
                          style={{ backgroundColor: option.status_color, width: 20, height: 20, borderRadius: '50%', marginRight: 10 }}
                        ></div>
                        <span>{option.status_text}</span>
                      </div>
                    ))}
                  </div>
                )}
               
              </div>

                          </div>

                          <div>
               <button className="btn btn-primary mt-4" onClick={handleCreateAutomationClick}>
        Create Automation
      </button>
            </div>       
          </div>
          
        )}
      </div>
    </div>
  );
};

export default AutomationModal;
