import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { TiInfoOutline } from 'react-icons/ti';

const ConfirmationPopup = ({ isOpen, onClose, onConfirm, isLoading }) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Body className="text-center">
        <TiInfoOutline className="text-danger mb-4 mx-auto" size={"3rem"} />
        <h2 className="text-2xl text-black mb-4">Are you sure?</h2>
        <p className="text-gray-600 mb-6">You won&apos;t be able to revert this!</p>
        <div className="d-flex justify-content-center">
          <Button variant="secondary" onClick={onClose} className="me-2">
            CANCEL
          </Button>
          <Button variant="danger" onClick={onConfirm} disabled={isLoading}>
            {isLoading ? <Spinner animation="border" size="sm" /> : 'DELETE'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationPopup;
