import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { FiTrash } from 'react-icons/fi';

const TaskFilesSection = ({ taskDetails, getFileSrcAndClass, updateTaskDetails, selectedCard, offcanvasState, setOffcanvasState,fetchData }) => {
  // const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCellId, setSelectedCellId] = useState(null); // Track the selected cell ID
  const fileInputRef = useRef(null); // Ref for file input

  const handleDeleteFile = async (fileId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/delete-doc-file/${fileId}/`, {
        method: 'GET', 
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error('Failed to delete file');
      }
      await updateTaskDetails(selectedCard);
      setOffcanvasState(!offcanvasState);
      fetchData()

      toast.success('File deleted successfully');
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && selectedCellId) { // Check if cell ID is available
      const formData = new FormData();
      formData.append('doc_file', file);
      formData.append('cell_id', selectedCellId); 
      formData.append('doc_type', 'main');

      try {
        const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/add-doc-file/`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload file');
        }
        await updateTaskDetails(selectedCard);
        setOffcanvasState(!offcanvasState);
        toast.success('File uploaded successfully!');
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleFileInputClick = (cellId) => {
    setSelectedCellId(cellId); // Set the cell ID when opening the file input
    fileInputRef.current.click(); // Trigger the file input click
  };

  const handlePaste = async (event) => {
    const items = event.clipboardData.items;
    for (const item of items) {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file && selectedCellId) {
          const formData = new FormData();
          formData.append('doc_file', file);
          formData.append('cell_id', selectedCellId); 
          formData.append('doc_type', 'main');

          try {
            const response = await fetch(`${process.env.REACT_APP_API_ROOT}/hotwash/add-doc-file/`, {
              method: 'POST',
              body: formData,
            });

            if (!response.ok) {
              throw new Error('Failed to upload file');
            }
            await updateTaskDetails(selectedCard);
            setOffcanvasState(!offcanvasState);
            toast.success('File uploaded successfully!');
          } catch (error) {
            console.error('Error uploading file:', error);
          }
        }
      }
    }
  };

  return (
    <div className="task-section task-files-section mx-2" onPaste={handlePaste}>
      <h5 className="section-title">Files:</h5>
      {taskDetails?.task
        .filter(item => item.column_type === "file") // Ensure that the filter is not based on docs length
        ?.map((fileItem, idx) => (
          <div key={idx} className="task-files">
            {fileItem.docs?.length > 0 ? (
              fileItem.docs.map((file, fileIdx) => {
                const { src, className } = getFileSrcAndClass(file.doc_file);
                return (
                  <div key={fileIdx} className="file-item-container">
                    <a
                      href={`${process.env.REACT_APP_API_ROOT}/${file.doc_file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="file-link"
                    >
                      <img
                        src={src}
                        alt="File"
                        className={`file-imgs ${className}`}
                      />
                    </a>
                    <div className="delete-icon" onClick={() => handleDeleteFile(file.id)}>
                      <FiTrash size={24} color="red" />
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No files available</p> // Show a message if no files are available
            )}
            <div className="add-file-section">
              <input
                type="file"
                onChange={handleFileChange}
                className="file-input"
                ref={fileInputRef} // Attach the ref to the input
                style={{ display: 'none' }} // Hide the file input element
              />
              <button onClick={() => handleFileInputClick(fileItem.id)} className="add-file-button">
                Add File
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TaskFilesSection;
