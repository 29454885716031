import React, { useEffect, useState } from 'react';
// import { useData } from './DataContext';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { FaPlus, FaTimes } from 'react-icons/fa';

function Table({ tableName , logo}) {
  const [selectedCellId, setSelectedCellId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userImages, setUserImages] = useState({});
  const [newItemPerson, setNewItemPerson] = useState('');
  const [newItemStatus, setNewItemStatus] = useState('');
  const [newItemTimeline, setNewItemTimeline] = useState('');
  const [newItemDependency, setNewItemDependency] = useState('');
  const [newItemTags, setNewItemTags] = useState('');
  const [newItemName, setNewItemName] = useState('');
  const [columns, setColumns] = useState(["Item", "Person", "Status", "Timeline", "Dependency", "Tags"]);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const[data,setData]=useState([
    {
           start: new Date(2024, 3, 1),
  
      end: new Date(2024, 3, 30),
      name: "Some Project",
      id: "ProjectSample",
      type: "project",
      hideChildren: false,
      item: 'verbal and non verbal', person: 'Laddu', status: 'Fundamental', timeline: [new Date(2024, 3, 1), new Date(2024, 3, 30)], dependency: 'email etiqutte', tags: 'Written Communication',
      styles: { backgroundColor: "blue", progressColor: "blue" },
    },
    {
      start: new Date(2024, 3, 1),
        end: new Date(2024, 3, 3),
      name: "Idea",
      id: "Task 0",
      type: "task",
      project: "ProjectSample",
      item: 'verbal and non verbal', person: 'anji', status: 'In Progress', timeline: [new Date(2024, 3, 1), new Date(2024, 3, 3)], dependency: 'Communication Basics', tags: 'personal Communication',
      styles: { backgroundColor: "orange", progressColor: "orange" },
  
    },
    {
      start: new Date(2024, 3, 10),
        end: new Date(2024, 4, 3),
      name: "Research",
      id: "Task 1",
     
      type: "task",
      project: "ProjectSample",
      item: 'verbal and non verbal', person: 'rama', status: 'Done', timeline: [new Date(2024, 3, 10), new Date(2024, 4, 3)], dependency: 'Technical Research', tags: 'Public Speaking',
      styles: { backgroundColor: "green", progressColor: "green" },
  
    },
    {
      start: new Date(2024, 3, 20),
      end: new Date(2024, 4, 28),
      name: "Discussion with team",
      id: "Task 2",
      type: "task",
      project: "ProjectSample",
      item: 'verbal and non verbal', person: 'santhu', status: 'Stuck', timeline: [new Date(2024, 3, 20), new Date(2024, 4, 28)], dependency: 'email etiqutte', tags: 'Innovation',
      styles: { backgroundColor: "red", progressColor: "red" },
  
    },
    {
      start: new Date(2024, 4, 10),
      end: new Date(2024, 4, 28),
      name: "Developing",
      id: "Task 3",
      type: "task",
      project: "ProjectSample",
      item: 'verbal and non verbal', person: 'Deepu', status: 'Done', timeline: [new Date(2024, 4, 10), new Date(2024, 4, 28)], dependency: 'Communication Basics', tags: 'personal Communication',
      styles: { backgroundColor: "green", progressColor: "green" },
  
    },
    {
      start: new Date(2024, 4, 28),
        end: new Date(2024, 5, 3),
      name: "Review",
      id: "Task 4",
      type: "task",
      project: "ProjectSample",
      item: 'verbal and non verbal', person: 'sree', status: 'Yet to Begin', timeline: [new Date(2024, 4, 28), new Date(2024, 5, 3)], dependency: 'email etiqutte', tags: 'Written Communication',
      styles: { backgroundColor: "black", progressColor: "Black" },
  
    },
    {
      start: new Date(2024, 3, 10),
      end: new Date(2024, 4, 3),
      name: "Release",
      id: "Task 6",
      type: "task",
      project: "ProjectSample",
      item: 'verbal and non verbal', person: 'John Doe', status: 'In Progress', timeline: [new Date(2024, 3, 10), new Date(2024, 4, 3)], dependency: 'email etiqutte', tags: 'Creativity',
      styles: { backgroundColor: "orange", progressColor: "orange" },
  
    },
    {
      start: new Date(2024, 4, 20),
        end: new Date(2024, 5, 3),
      name: "Party Time",
      id: "Task 9",
      type: "task",
      project: "ProjectSample",
      item: 'verbal and non verbal', person: 'John Doe', status: 'In Progress', timeline: [new Date(2024, 4, 20), new Date(2024, 5, 3)], dependency: 'Technical Research', tags: 'Public Speaking',
      styles: { backgroundColor: "orange", progressColor: "orange" },
  
    }
  ])

  const handleDateSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setStartDate(startDate);
    setEndDate(endDate);
    applySelectedDates(startDate, endDate);
    setSelectedCellId(null); 
  };

  useEffect(() => {
    // setData()
    fetchUserImages();
  }, []);
  const fetchUserImages = async () => {
    try {
      const response = await fetch('https://api.unsplash.com/photos/random?count=10&query=portrait', {
        headers: {
          Authorization: 'Client-ID rotoDwd-ys7g_EZJckoZJuQfCNbDDDTVz65UY85IB-8'
        }
      });
      if (response.ok) {
        const imagesData = await response.json();
        const images = imagesData.map(image => ({
          id: image.id,
          url: image.urls.small
        }));
        setUserImages(images);
      } else {
        throw new Error('Failed to fetch images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleCellClick = (id) => {
    setSelectedCellId(id);
    const cellData = data.find(item => item.id === id);
    if (cellData) {
      setStartDate(cellData.timeline[0]);
      setEndDate(cellData.timeline[1]);
    }
  };

  const applySelectedDates = (startDate, endDate) => {
    if (selectedCellId !== null) {
      const newData = data.map(item => {
        if (item.id === selectedCellId) {
          return { ...item, timeline: [startDate, endDate] ,start:startDate,end:endDate };
        }
        return item;
      });
      setData(newData);
    }
  };


  const formatDate = (startDate, endDate) => {
    if (!startDate || !endDate) return '';
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const month = startDate.toLocaleString('default', { month: 'short' });
    return `${startDay}-${endDay} ${month}`;
  };

  const { length: imagesCount } = userImages;

  const getRandomUserImage = () => {
    if (imagesCount > 0) {
      const randomIndex = Math.floor(Math.random() * imagesCount);
      return userImages[randomIndex].url;
    }
    return ''; 
  };

  const getStatusCellStyle = (status) => {
    switch (status) {
      case 'Fundamental':
        return { backgroundColor: 'brown', color: 'white' };
      case 'In Progress':
        return { backgroundColor: 'orange', color: 'white' };
      case 'Stuck':
        return { backgroundColor: 'red', color: 'white' };
      case 'Yet to Begin':
        return { backgroundColor: 'black', color: 'white' };
      case 'Done':
        return { backgroundColor: 'green', color: 'white' };
      default:
        return {};
    }
  };

  let tableStyle = {};
  let headingStyle = {};
  switch (tableName) {
    case 'env59':
      tableStyle = { borderLeft: '10px solid green' };
      headingStyle = { color: 'green' };
      break;
    case 'env89':
      tableStyle = { borderLeft: '10px solid blue' }; 
      headingStyle = { color: 'blue' };
      break;
    case 'Professional Settings':
      tableStyle = { borderLeft: '10px solid pink' };
      headingStyle = { color: 'pink' };
      break;
    default:
      break;
  }
  const handleKeyPress = (e, id) => {
    if (e.key === 'Enter') {
      const newItem = {
        id: data.length + 1,
        name: newItemName,
        person: newItemPerson,
        status: newItemStatus,
        timeline: newItemTimeline,
        dependency: newItemDependency,
        tags: newItemTags,
      };
      const newData = [...data, newItem];

      setData(newData);
      setNewItemName('');
      setNewItemPerson('');
      setNewItemStatus('');
      setNewItemTimeline('');
      setNewItemDependency('');
      setNewItemTags('');

      
      
    }
  };
  const handleItemNameChange = (e, id) => {
    const newName = e.target.value;
    const newData = data.map(row => {
      if (row.id === id) {
        return { ...row, name: newName };
      }
      return row;
    });
    setData(newData);
  };
  const handleAddColumnClick = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleAddColumn = (columnName) => {
    // Add new column to the columns state
    setColumns([...columns, columnName]);
  
    // Update data state to include the new column for each row
    const updatedData = data.map(row => ({
      ...row,
      [columnName.toLowerCase()]: '' // Initialize new column with empty value
    }));
  console.log("updatedData",updatedData)
    setData(updatedData);
    setIsMenuVisible(false); // Close the add column menu
  };
  
  
  return (
    <div className="table-container">
      <h4 style={headingStyle}><img className="frame-logodetails me-2"  alt ='logo' src={`http://127.0.0.1:8000${logo}`}></img>Sheet1</h4>
      {selectedCellId !== null && (
        <div className="date-range-picker-container" style={{ zIndex: 100, position: 'absolute' }}>
          <DateRangePicker
            ranges={[{ startDate, endDate, key: 'selection' }]}
            onChange={handleDateSelect}
            showDateDisplay={false}
            showSelectionPreview={false}
          />
        </div>
      )}
      <table className="table table-bordered table-hover mt-4" style={tableStyle}>
        <thead>
        <tr>
        <th><input type="checkbox" /></th>
{columns.map((col, index) => (
  <th key={index}>{col}</th>
  
))}
<th>
  <div className="add-column-button-container">
    <button onClick={handleAddColumnClick} style={{border:"none"}}>
    {isMenuVisible ? <FaTimes /> : <FaPlus />}
    </button>
    {isMenuVisible && (
      <div className="add-column-menu">
        {["Column1", "Column2", "Column3"].map((col, index) => (
          <div key={index} onClick={() => handleAddColumn(col)}>
            {col}
          </div>
        ))}
      </div>
    )}
  </div>
</th>

          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id}>
                <td><input type="checkbox" /></td>
             
              <td>
             <input
  type="text"
  value={item.name}
  onChange={(e) => handleItemNameChange(e, item.id)}
  className="input-no-border"
/>

            </td>
            
              
            
                  <td className='select-container' >
                  <div className="d-flex align-items-center"  >
                    {getRandomUserImage() ? (
                      <img src={getRandomUserImage()} alt={item.person} className="rounded-circle me-2" width="30" height="30" />
                    ) : (
                      <div className="rounded-circle me-2" style={{ width: '30px', height: '30px', backgroundColor: '#ccc' }} />
                    )} 
                    <select
                      value={item.person}
                      onChange={(e) => {
                        const newPerson = e.target.value;
                                                 const newData = data.map(row => {
                            if (row.id === item.id) {
                              return { ...row, person: newPerson };
                            }
                            return row;
                          });
                          setData(newData);
                        
                       
                      }}
                      className="select-name select-no-border "
  
                    >
                      {data?.map((item) => (
                        <option value={item?.person} style={{ color: 'black' }}>{item?.person}</option>
                      ))}
  
                    </select>
                  </div>
                </td>
               
            
              
              <td style={{  ...getStatusCellStyle(item.status) }}>
                <select
                  value={item.status}
                  onChange={(e) => {
                    const newStatus = e.target.value;
                    const newData = data.map(row => {
                      if (row.id === item.id) {
                        return { ...row, status: newStatus };
                      }
                      return row;
                    });
                    setData(newData);
                  }}
                  className="select-status select-no-border"
                >
                  <option value="Fundamental" style={{ backgroundColor: 'brown', color: 'white' }}>Fundamental</option>
                  <option value="In Progress" style={{ backgroundColor: 'orange', color: 'white' }}>In Progress</option>
                  <option value="Stuck" style={{ backgroundColor: 'red', color: 'white' }}>Stuck</option>
                  <option value="Yet to Begin" style={{ backgroundColor: 'black', color: 'white' }}>Yet to Begin</option>
                  <option value="Done" style={{ backgroundColor: 'green', color: 'white' }}>Done</option>
                </select>
              </td>
           
              
              <td onClick={() => handleCellClick(item.id)} style={{ cursor: 'pointer' }}>
                {formatDate(item.timeline[0], item.timeline[1])}
              </td>
             
               
              <td className='input-no-border'contentEditable="true" style={{ color: 'white' }}>{ <span style={{ backgroundColor: '#add8e6', borderRadius: '10px', padding: '2px 10px', display: 'inline-block' }}>{item.dependency}</span>}</td>
                
                                   <td style={{ backgroundColor: '#add8e6', borderRadius: '10px', padding: '2px 10px', display: 'inline-block',alignItems: 'center', justifyContent: 'center',marginTop:'5px',marginLeft:'5px', }}>
                  <select

                    value={item.tags}
                    onChange={(e) => {
                      const newStatus = e.target.value;
                      const newData = data.map(row => {
                        if (row.id === item.id) {
                          return { ...row, tags: newStatus };
                        }
                        return row;
                      });
                      setData(newData);
                    }}
                    className="select-status  select-no-border"
                  >
                   {data?.map((item) => (
                        <option value={item?.tags} style={{ color: 'black' }}>{item?.tags}</option>
                      ))}
                  </select>
                </td>
              
              </tr>

          ))}
           <tr>
            <td><input type="checkbox" disabled checked={false} /></td>
            <td><input style={{ width: "130px", color: "black" ,textAlign:'center'}} type='text' onKeyDown={(e) => handleKeyPress(e, data.length + 1)} value={newItemName} onChange={(e) => setNewItemName(e.target.value)} placeholder='Add item' /></td>
            <td contentEditable="true" onKeyDown={(e) => handleKeyPress(e, data.length + 1)}>{newItemPerson}</td>
            <td contentEditable="true" onKeyDown={(e) => handleKeyPress(e, data.length + 1)}>{newItemStatus}</td>
            <td contentEditable="true" onKeyDown={(e) => handleKeyPress(e, data.length + 1)}>{newItemTimeline}</td>
         
              <td className='input-no-border'contentEditable="true"  onKeyDown={(e) => handleKeyPress(e, data.length + 1)} style={{ color: 'white' }}>{ <span style={{ backgroundColor: '#add8e6', borderRadius: '10px', padding: '2px 10px', display: 'inline-block' }}>{newItemDependency}</span>}</td>
              
            <td contentEditable="true" onKeyDown={(e) => handleKeyPress(e, data.length + 1)}>{newItemTags}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Table;
