import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';

const ShareWorkspaceModal = ({ showModal, handleClose, workspaceId, sharedById, users }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [accessType, setAccessType] = useState('');
  const [subscriberAccess, setSubscriberAccess] = useState('');

  // Filter out the user who is already shared (sharedById)
  const filteredUsers = users.filter(user => user.id !== sharedById);

  const handleShareSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      workspace_id: workspaceId,
      shared_by: sharedById,
      shared_with: selectedUser?.value, // Single user ID
      acccess_type: accessType,
      subscriber_access: accessType === 'subscriber' ? subscriberAccess : null,
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_ROOT}/hotwash/share-workspace/`, payload);
      toast.success('Workspace shared successfully!');
      handleClose(); // Close the modal after success
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.status;
        if (errorMessage === "Workspace already Share with selected users") {
          toast.error("Workspace already Shared with selected users");
        } else {
          toast.error("An error occurred. Please try again.");
        }
      } else {
        console.error('Error Workspace sheet:', error);
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <div>
          <Modal.Title className="text-primary">Share This Workspace</Modal.Title>
          <p className="text-muted small mb-0 mt-2">
            Invite your team to collaborate on this workspace. Select a user and define their access permissions.
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleShareSubmit}>
          {/* User Selection Dropdown */}
          <Form.Group controlId="userSelect" className="mb-4">
            <Form.Label className="font-weight-bold">Select User</Form.Label>
            <Select
              options={filteredUsers.map(user => ({
                value: user.id,
                label: user.name,
                file_name: user.file_name,
              }))}
              onChange={setSelectedUser}
              placeholder="Search or select a user"
              className="react-select-container"
              formatOptionLabel={(e) => (
                <div className="d-flex align-items-center">
                  <img
                    src={`${process.env.REACT_APP_TOS_PATH}/includes/Pages/upload/${e.file_name}`}
                    alt={e.name}
                    style={{ width: 30, height: 30, borderRadius: '50%' }}
                  />
                  <span className="ms-2">{e.label}</span>
                </div>
              )}
              required
            />
          </Form.Group>

          {/* Access Type Selection */}
          <Form.Group controlId="accessType" className="mb-4">
            <Form.Label className="font-weight-bold">Access Type</Form.Label>
            <div>
              <Form.Check
                inline
                label="Admin"
                type="radio"
                id="adminAccess"
                value="admin"
                checked={accessType === 'admin'}
                onChange={(e) => setAccessType(e.target.value)}
                className="custom-radio"
                required
              />
              <Form.Check
                inline
                label="Subscriber"
                type="radio"
                id="subscriberAccess"
                value="subscriber"
                checked={accessType === 'subscriber'}
                onChange={(e) => setAccessType(e.target.value)}
                className="custom-radio"
                required
              />
            </div>
          </Form.Group>

          {/* Subscriber Access Options */}
          {accessType === 'subscriber' && (
            <Form.Group controlId="subscriberAccess" className="mb-4">
              <Form.Label className="font-weight-bold">Subscriber Access Level</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="View Only"
                  type="radio"
                  id="viewOnly"
                  value="view_only"
                  checked={subscriberAccess === 'view_only'}
                  onChange={(e) => setSubscriberAccess(e.target.value)}
                  className="custom-radio"
                  required
                />
                <Form.Check
                  inline
                  label="Can Edit"
                  type="radio"
                  id="canEdit"
                  value="can_edit"
                  checked={subscriberAccess === 'can_edit'}
                  onChange={(e) => setSubscriberAccess(e.target.value)}
                  className="custom-radio"
                  required
                />
              </div>
            </Form.Group>
          )}

          {/* Modal Footer with buttons */}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} className="px-4">
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              className="px-4"
              disabled={!selectedUser || !accessType}
            >
              Share Workspace
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ShareWorkspaceModal;
