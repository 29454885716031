import React from 'react';
import '../../assets/styles/Dashboard.css';
// import DynamicTable from '../common/DynamicTable';
import Table from '../common/Table';

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <h4 className='py-2'>Home</h4>
      <div className="dashboard-content " >
      <Table tableName={'Main Table'} />

      </div>
    </div>
  );
}

export default Dashboard;
